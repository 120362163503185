




















































import { mapActions } from 'vuex';
import alertS from '../../services/AlertService/AlertService';
import { copyTextToClipboard } from '../../services/Copy';
import DestroyAcc from '../DestroyAcc.vue';
import rService from '../../services/RequestService/RequestServiceSetup';

export default {
  name: 'ProfilePopup',
  components: {
    DestroyAcc,
    'vs-popup': () => import('@/components/Popup/VSPopup.vue'),
  },
  props: {
    mobile: {
      Type: Boolean,
      default: false,
    },
    userInfo: {
      email: String,
      user: String,
      user_email: String,
      image: String,
      unlock_role: Boolean,
      plan: {
        Type: String,
        default: 'No Plan',
      },
      required: true,
    },
  },
  data() {
    return {
      user_email: '',
      editEmail: false,
      loadingR: false,
      popupActive: false,
    };
  },
  watch: {
    popupActive(newVal) {},
  },
  beforeMount() {
    this.user_email = this.userInfo.user_email;
  },
  methods: {
    closePop(e) {
      this.$store.commit('closeSideBar');
      this.popupActive = false;
    },
    open() {
      this.popupActive = true;
    },
    copy(text, e) {
      const res = copyTextToClipboard(text);
      try {
        const successful = document.execCommand('copy');
        const msg = successful ? 'successful' : 'unsuccessful';
        this.$copied(`<p>Copied to clipboard:<br>${text}</p>`);
      } catch (err) {
        console.error('Fallback: Oops, unable to copy', err, text);
      }
    },
    disableEdit() {
      this.editEmail = !this.editEmail;
      if (!this.editEmail) {
        this.user_email = this.userInfo.user_email;
      }
    },
    async saveEmail(email: string) {
      const res = await rService.post(`change_email/${this.userInfo.platform}`, { email });
      if (res.success) {
        alertS.success('Email changed successfully');
        this.updateUserEmail(email);
        this.editEmail = false;
      }
    },
    async regenerate() {
      this.loadingR = true;
      await this.regenerateToken();
      this.regenerate_bot_links();
      this.loadingR = false;
    },
    ...mapActions(['updateUserEmail']),
    ...mapActions(['toggleNavBar', 'regenerateToken']),
    ...mapActions('socket', ['regenerate_bot_links']),
    checkEmail(email: string) {
      this.$store.commit('updateCheckInput', email);
      this.popupActive = false;
      if (!this.desktop) {
        this.toggleNavBar();
      }
    },
    getPlan() {
      this.$router.push({ name: 'pricing' }).catch();
      this.popupActive = false;
    },
  },
  computed: {
    plan() {
      return this.$vuetify.lang.t(`$vuetify.Plans.${this.userInfo.plan}`);
    },
    expiration_date() {
      return this.userInfo.expiration_date == 'lifetime' ? 'Lifetime' : `${this.$moment(this.userInfo.expiration_date).format('YYYY-MM-DD HH:mm:ss')} (${this.$moment(this.userInfo.expiration_date).fromNow()})`;
    },
  },
};
