







import { vueTelegramLogin } from 'vue-telegram-login';
import rService from '../../../services/RequestService/RequestServiceSetup';
import alertS from '../../../services/AlertService/AlertService';
import { mapActions } from 'vuex';
export default {
  name: 'TelegramLogin',
  components: { vueTelegramLogin },
  data() {
    return {
      platform: 'telegram'
    };
  }
};
