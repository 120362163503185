



























// @ is an alias to /src
import Announce from '@/components/Announce/Announce.vue';
import Cooldown from '@/components/CheckAccount/Cooldown.vue';
//import CookieConsent from '@/components/CookieConsent/CookieConsent.vue';
import Language from '@/components/Language/Language.vue';
import Layout from '@/components/Layout/Layout.vue';
import Popup from '@/components/Popup/Popup.vue';
import Snack from '@/components/Snack/Snack.vue';
import SnackCopy from '@/components/SnackCopy/SnackCopy.vue';
import alertS from '@/services/AlertService/AlertService';
import { isAndroid } from '@/services/Device';
import Autolinker from 'autolinker';
import { mapActions, mapGetters } from 'vuex';
import rService from '../services/RequestService/RequestServiceSetup';

export default {
  name: 'App',
  components: {
    particles: () => import('@/components/Particles/Particles.vue'),
    Popup,
    SnackCopy,
    Layout,
    Announce,
    Snack,
    Language,
    Cooldown,
    //CookieConsent
  },
  data() {
    return {
      localKey: 'notShowNoticeAgain5',
      alertNotice: true,
      isActive: false,
      position: 0,
      scrollDown: false,
    };
  },
  destroyed() {
    if (this.$refs.content) this.$refs.content.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    toggleBodyClass(addRemoveClass, className) {
      const el = document.body;
      if (addRemoveClass === true) {
        el.classList.add(className);
      } else {
        el.classList.remove(className);
      }
    },
    changeMode(dark = false) {
      if (dark) {
        this.toggleBodyClass(true, 'dark_mode');
        (this.$vuetify.theme.themes.dark.navBarBlue = '#1e1e20'), (this.$vuetify.theme.themes.dark.sideBarBlue = '#303037'), (this.$vuetify.theme.themes.dark.darkColor = '#303037');
        this.$vuetify.theme.themes.dark.primary = '#666666';
      } else {
        this.toggleBodyClass(false, 'dark_mode');
        (this.$vuetify.theme.themes.dark.navBarBlue = '#373774'), (this.$vuetify.theme.themes.dark.sideBarBlue = '#494ca2'), (this.$vuetify.theme.themes.dark.darkColor = '#1E1E1E');
        this.$vuetify.theme.themes.dark.primary = '#2196f3';
      }
    },
    setMenuItems() {
      try {
        const menu_storage = localStorage.getItem('menu');
        if (menu_storage) {
          const data = JSON.parse(menu_storage);
          if (data.length > 0) {
            const menu_items = {};
            data.forEach((item) => {
              menu_items[item.name] = item.value !== null;
            });
            this.$store.commit('setMenuItems', menu_items);
          }
        }
      } catch (e) {}
    },
    autoLink(text: string) {
      return Autolinker.link(text);
    },
    ...mapActions(['updateScroll', 'openSheet', 'setUniqueId', 'setLoading']),
    handleScroll(event) {
      const position = event.target.scrollTop;
      this.scrollDown = position > 0;
      this.position = position;
    },
    setUniqueIdHash() {
      const unique_id = this.$cookies.get('uniqueIdHash');
      const session_token = this.$cookies.get('session_token');
      if (unique_id && session_token) {
        rService.setupUniqueIdHash(unique_id, session_token);
        this.setUniqueId(unique_id);
        this.$store.dispatch('authenticate', {
          access_token: unique_id,
          platform: 'general',
          auto: true,
        });
      } else {
        this.setLoading(false);
      }
    },
    showAdvert() {
      if (!isAndroid()) return;
      const advertiseApk = !(localStorage.notAdvertiseApk != undefined);
      this.openSheet(advertiseApk);
    },
    finishHelp() {
      this.$store.state.snackbar = {
        open: true,
        text: this.$vuetify.lang.t('$vuetify.Video.finisHelp'),
        closeEnabled: true,
      };
      localStorage.tutorialCompleted = true;
    },
    loadLanguages() {
      rService.get('geolocation', true).then((res) => {
        const enabledLocation = ['es', 'en'];
        if (localStorage.language && enabledLocation.includes(localStorage.language)) {
          this.$vuetify.lang.current = localStorage.language;
          this.$moment.locale(localStorage.language);
        } else if (res && res.lang && enabledLocation.includes(res.lang)) {
          this.$vuetify.lang.current = res.lang;
          this.$moment.locale(res.lang);
          this.$forceUpdate();
        }
      });
    },
    tNoticeInfo(text: string) {
      return this.$vuetify.lang.t('$vuetify.NoticeInfo.' + text);
    },
  },
  computed: {
    ...mapGetters(['userInfo', 'loadingBar', 'safe', 'copied', 'snackBarOpen', 'sheetOpen', 'languagePopupOpen']),
  },
  watch: {
    '$route.name'(newVal) {
      this.$vuetify.goTo(0, {
        duration: 300,
        offset: 0,
        easing: 'easeInOutCubic',
      });
    },
    alertNotice(newVal) {
      if (newVal == false && localStorage[this.localKey] !== 'true') {
        const tM = this.$vuetify.lang.t('$vuetify.Notice');
        this.$swal
          .fire({
            title: this.tNoticeInfo('title'),
            text: this.tNoticeInfo('text'),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: this.tNoticeInfo('button'),
          })
          .then(async (result) => {
            if (result.value) {
              this.$swal.fire(this.tNoticeInfo('titleS'), this.tNoticeInfo('textS'), 'success');
              localStorage[this.localKey] = 'true';
            } else {
              this.alertNotice = true;
            }
          });
      }
    },
    'userInfo.unique_id'(unique_id, previous) {
      if (unique_id) {
        this.$socket.emit('uniqueIdHash', unique_id);
      } else if (previous) {
        this.$socket.emit('emit_logout', previous);
      }
    },
    scrollDown(newVal) {
      this.updateScroll(newVal);
    },
  },
  created() {
    this.showAdvert();
    alertS.injectVuetify(this.$vuetify);
    this.loadLanguages();
    this.$store.subscribe((mutation, state) => {
      switch (mutation.type) {
        case 'socket/SOCKET_alert':
          const Swal = this.$swal;
          Swal.fire(mutation.payload);
          break;
        case 'setDarkMode':
          this.changeMode(mutation.payload);
          break;
        case 'finishHelp':
          this.finishHelp();
          break;
        case 'updateSideBarActive':
          this.isActive = state.sideBarStatus;
          break;
        case 'openSideBar':
          const elem = this.$refs.layout;
          if (elem) elem.open();
          break;
        case 'closeSideBar':
          const elem1 = this.$refs.layout;
          if (elem1) elem1.close();
          break;
        default:
          break;
      }
    });
  },
  beforeMount() {
    const dark_mode = localStorage.getItem('dark_mode') === 'true';
    this.changeMode(dark_mode);
    if (window.location.href.indexOf('leakcheck.cc') > -1) {
      window.location.replace('https://checkleaked.cc');
    }
    if (window.localStorage[this.localKey]) {
      this.alertNotice = false;
    }
    this.setUniqueIdHash();
    this.setMenuItems();
    (window as any).Tawk_API.customStyle = {
      zIndex: 50,
    };
  },
};
