






































































// It can also be imported as { GoogleLogin }

import ClickOutside from 'vue-click-outside';
import { mapGetters } from 'vuex';
import { isAndroid } from '../../services/Device';
import ProfileUser from '../ProfileNav/NewProfileNav.vue';
import DialogLogin from './DialogLogin.vue';
import LoginEventApp from './LoginComponents/LoginEventApp.vue';
import DestroyAcc from '../DestroyAcc.vue';
import LinkDownload from './LinkDownload.vue';
import Link from './Link.vue';
import ServerTime from '../ServerTime/ServerTime.vue';

/* eslint-disable @typescript-eslint/camelcase */
export default {
  name: 'SideBar',
  data() {
    return {
      dark_mode: false,
      loggedOut: false,
      isActive: false,
      hideChat: false,
      timeout: null,
    };
  },
  components: {
    DestroyAcc,
    LoginEventApp,
    DialogLogin,
    ProfileUser,
    Link,
    LinkDownload,
    ServerTime,
  },
  beforeDestroy() {
    if (this.timer) clearTimeout(this.timer);
  },
  methods: {
    changeDarkMode() {
      const val = this.dark_mode;
      if (val) {
        localStorage.setItem('dark_mode', val);
      } else {
        localStorage.removeItem('dark_mode');
      }
      this.$store.commit('setDarkMode', val);
    },
    setDarkMode() {
      const dark_mode = localStorage.getItem('dark_mode');
      this.dark_mode = dark_mode;
    },
    updateChat(hideChat, att = 0) {
      try {
        att++;
        const Tawk_API = (window as any).Tawk_API;
        if (Tawk_API) {
          if (hideChat) {
            Tawk_API.minimize();
            Tawk_API.hideWidget();
          } else {
            Tawk_API.showWidget();
          }
        } else if (att < 2) {
          this.$nextTick(() => {
            this.timer = setTimeout(() => {
              this.updateChat(hideChat, att);
            }, 500);
          });
        }
      } catch (e) {
        console.error(e);
      }
    },
    hideChatUpdate() {
      const valLocal = this.hideChat ? 'true' : 'false';
      window.localStorage.setItem('hideChat', valLocal);
      this.updateChat(this.hideChat);
    },
    checkStorageHide() {
      const hideChatL = window.localStorage.getItem('hideChat');
      if (hideChatL == 'true') {
        this.hideChat = true;
        this.updateChat(this.hideChat);
      }
    },
    openLanguagePopup() {
      this.$store.state.languagePopup = true;
    },
    closeOutside() {
      if (this.isActive) {
        this.isActive = false;
      }
    },
    open() {
      this.isActive = true;
    },
    deleteEntries() {
      this.$store.commit('openPopup', 'deleteEntries');
    },
    translate(prop) {
      return this.$vuetify.lang.t(`$vuetify.SideBar.${prop}`);
    },
  },
  directives: {
    ClickOutside,
  },
  computed: {
    isAndroid() {
      return isAndroid();
    },
    ...mapGetters(['userInfo', 'navBarStatus', 'loading', 'loggedIn', 'menuItems']),
    desktop() {
      return this.windowWidth >= 768;
    },
  },
  watch: {
    isActive(newVal) {
      this.$store.commit('updateSideBarActive', newVal);
    },
    $route(to, from) {
      this.isActive = false;
    },
    navBarStatus(newItem) {
      this.isActive = false;
    },
  },
  beforeMount() {
    this.checkStorageHide();
    this.setDarkMode();
  },
};
