<template>
  <v-list-item class="linkSideBar" link>
    <a class="d-flex w-100" :href="link" download>
      <v-list-item-action>
        <span v-bind:class="classIcon" class="w-80"></span>
      </v-list-item-action>
      <v-list-item-content align="left" class="w-100">
        <v-list-item-title>{{ linkText }}</v-list-item-title>
      </v-list-item-content>
    </a>
  </v-list-item>
</template>

<script>
export default {
  name: 'Link',
  props: {
    link: String,
    linkText: String,
    classIcon: String
  }
};
</script>

<style lang="scss">
.linkSideBar {
  a {
    text-decoration: none;
  }
}
</style>
