













import { mapGetters } from 'vuex';
export default {
  name: 'Link',
  props: {
    menuName: String,
    routeName: String,
    linkText: String,
    classIcon: String,
    query: {
      type: Object,
      required: false,
      default: null,
    },
  },
  computed: {
    ...mapGetters(['menuItems']),
  },
};
