










import alertS from '@/services/AlertService/AlertService';
const googleId = '305807321503-beb5eu61tohp07ci7mq4mk63f7s5k68h.apps.googleusercontent.com';
import GoogleSignInButton from 'vue-google-identity-login-btn';
/* eslint-disable @typescript-eslint/camelcase */
export default {
  name: 'GoogeLogin',
  props: {},
  directives: {
    GoogleSignInButton,
  },
  data() {
    return {
      clientId: googleId,
      showOverlay: false,
      platform: 'google',
      disabled: false,
      client: null,
    };
  },
  methods: {
    login() {
      (window as any).google_client.requestAccessToken();
    },
    async load_google() {
      if (document.getElementById('google_client')) {
        const client = (window as any).google_client;
        if (client) {
          if (client === 'failed') return false;
          return client;
        } else {
          setTimeout(() => {
            this.load_google();
          }, 100);
        }
      }
      return new Promise((resolve, reject) => {
        const script = document.createElement('script');
        script.src = 'https://accounts.google.com/gsi/client';
        script.id = 'google_client';
        document.getElementsByTagName('head')[0].appendChild(script);
        script.onload = () => {
          console.log('Script loaded');
          var client = (window as any).google.accounts.oauth2.initTokenClient({
            client_id: this.clientId,
            scope: 'openid email profile',
            ux_mode: 'popup',
            response_type: 'id_token token',
            callback: this.onGoogleAuthSuccess,
          });
          (window as any).google_client = client;
          resolve(client);
        };
        script.onerror = (message, url, line, column, error) => {
          console.log('Error', message);
          (window as any).google_client = 'failed';
          reject({ message, url, line, column, error });
        };
      });
    },
    onFailure(err) {
      console.error(err);
      this.$store.commit('destroyUserInfo', this.platform);
      alertS.error('Login with Google Failed');
    },
    async onGoogleAuthSuccess(googleUser) {
      if (googleUser) {
        console.log(googleUser);
        const id_token = googleUser.access_token;
        console.log('id token', id_token);
        if (!id_token) throw new Error('NO id_token found');
        this.$store.dispatch('authenticate', {
          access_token: id_token,
          platform: this.platform,
        });
      } else {
        this.$store.commit('destroyUserInfo', this.platform);
      }
      this.disableBtn = false;
    },
    triggerBtn() {
      this.$refs.googleBtn.$el.click();
    },
  },
  async beforeMount() {
    this.client = await this.load_google().catch((e) => false);
    if (this.client === false) {
      this.disabled = true;
      alertS.error(
        "Google Login it's not working (Please enable cookies and refresh the page if you want to use it).<br/>If you don't know how you can check this link: <a href='https://support.google.com/accounts/answer/61416?co=GENIE.Platform%3DDesktop&hl=en' target='_blank' >Google Tutorial of How to Enable Cookies</a>"
      );
      this.$store.commit('destroyUserInfo', this.platform);
      this.showOverlay = true;
    }
  },
  computed: {
    logginState() {
      return this.$store.state.platformState.google;
    },
  },
  watch: {
    logginState(newCount, oldCount) {
      if (oldCount == 1 && newCount == 0) this.triggerBtn();
    },
  },
};
