<template>
  <v-footer color="sideBarBlue" class="footer-leak font-weight-medium" padless>
    <v-row justify="center" no-gutters>
      <div class="shadow-container shadow w-100">
        <v-btn v-for="(link, index) in links" :key="index" color="white" text rounded class="my-2">
          <router-link class="text-white" :to="{ name: link.router }">
            <span :class="'fa ' + link.icon + ' mr-3'"></span>
            {{ link.name }}
          </router-link>
        </v-btn>
        <v-btn text target="_blank" href="https://www.linkedin.com/company/checkleakedcc/">Linkedin</v-btn>
        <v-btn text target="_blank" href="https://www.trustpilot.com/review/checkleaked.cc">Reviews</v-btn>
        <v-btn text target="_blank" href="https://status.checkleaked.cc/"> Status </v-btn>
        <v-col class="sideBarBlue lighten-2 py-2 text-center px-3 white--text" cols="12">
          <a class="attribute text-white" href="https://www.freepik.com/free-photos-vectors/background">Background vector created by starline - www.freepik.com</a>
        </v-col>
      </div>
      <v-col class="py-2 bg-footer text-center white--text" cols="12">
        <span>Copyright CheckLeaked.cc &copy; {{ new Date().getFullYear() }}</span>
      </v-col>
    </v-row>
  </v-footer>
</template>

<style lang="scss">
.theme--light.v-footer {
  &.footer-leak {
    .shadow-container {
      z-index: 1;
    }
    background-color: #373774;
    a,
    span {
      color: white;
      text-align: left;
    }
  }
  .bg-footer {
    background: linear-gradient(90deg, rgb(0, 31, 108) 0%, rgb(20, 82, 150) 100%);
  }
}
</style>

<script>
import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters(['userInfo']),
  },
  data() {
    return {
      links: [
        {
          icon: 'fa-home',
          name: 'Home',
          router: 'landing',
        },
        {
          icon: 'fa-lock',
          name: 'Privacy Policies',
          router: 'privacy',
        },
        {
          icon: 'fa-gavel',
          name: 'Terms and Conditions',
          router: 'terms',
        },
      ],
    };
  },
};
</script>
