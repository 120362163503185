









export default {
  name: 'ServerTime',
  data() {
    return {
      currentTime: this.$moment.utc(),
      interval: null,
    };
  },
  beforeDestroy() {
    if (this.interval) clearInterval(this.interval);
  },
  methods: {
    startInterval() {
      this.interval = setInterval(() => {
        this.currentTime = this.$moment.utc();
      }, 1000);
    },
  },
  computed: {
    formattedTime() {
      return this.currentTime.format('YYYY/MM/DD HH:mm:ss');
    },
  },
  beforeMount() {
    this.startInterval();
  },
};
