
















import ProfilePopup from '../Profile/NewProfile.vue';
import { mapGetters } from 'vuex';
import alertS from '../../services/AlertService/AlertService';
import ProfileUser from '../ProfileNav/NewProfileNav.vue';

export default {
  name: 'NavBar',
  components: {
    ProfilePopup,
    ProfileUser,
  },
  props: {
    title: String,
    isActive: Boolean,
  },
  data() {
    return {
      windowPos: 0,
      hideTitle: false,
      showUserInfo: false,
    };
  },
  methods: {
    hideShow() {
      const stopped = false;
      let prevScrollpos = window.pageYOffset;
      window.onscroll = () => {
        const currentScrollPos = window.pageYOffset;
        const currenHidden = this.isHidden;
        if (!stopped) {
          if (prevScrollpos > currentScrollPos) {
            this.isHidden = false;
          } else {
            this.isHidden = true;
          }
        }
        prevScrollpos = currentScrollPos;
      };
    },
    changeStatusNav() {
      const newActive = !this.isActive;
      this.$emit('update:isActive', newActive);
    },
  },
  mounted() {
    this.hideShow();
  },
  computed: {
    ...mapGetters(['scrollDown', 'sideBarStatus', 'userInfo', 'loggedIn']),
    hideNavC() {
      return true;
    },
    classComputed() {
      if (this.userInfo.email && this.windowWidth < 769) {
        return 'd-none';
      }
      return 'd-flex';
    },
  },
  watch: {},
};
