import '@/assets/fontawesome-pro/css/all.min.css';
import '@/scss/main.scss';
import VueCountdown from '@chenfengyuan/vue-countdown';
import 'leaflet/dist/leaflet.css';
import lodash from 'lodash';
import { register } from 'register-service-worker';
import Vue from 'vue';
import Chat from 'vue-beautiful-chat';
// import VueWindowSize from 'vue-window-size'
import VueCookies from 'vue-cookies';
import VueLodash from 'vue-lodash';
import moment from 'vue-moment';
import VueParticles from 'vue-particles';
import LoadScript from 'vue-plugin-load-script';
// @ts-ignore
import VuePlyr from 'vue-plyr';
import VueSocketIO from 'vue-socket.io';
import VueSweetalert2 from 'vue-sweetalert2';
import App from './App/NewApp.vue';
import config from './config/env';
import vuetify from './plugins/vuetify';
import router from './router';
import MyLoadingPlugin from './services/loading';
import store from './store';
import Tawk from 'vue-tawk'
Vue.use(Tawk, {
  tawkSrc: 'https://embed.tawk.to/62a683827b967b117994279e/1g5d73dhd'
})


Vue.use(LoadScript)
declare module 'vue-sweetalert2';

Vue.use(Chat);
Vue.config.productionTip = false;
Vue.use(VueSweetalert2);
Vue.use(moment);
Vue.use(VueParticles);
// Vue.use(VueWindowSize);
Vue.use(VueCookies);
Vue.$cookies.config('7d');
Vue.use(VueLodash, { name: 'custom', lodash: lodash });
Vue.component(VueCountdown.name, VueCountdown);
Vue.use(VuePlyr, {
  plyr: {
    fullscreen: { enabled: true }
  },
  emit: ['ended']
});

const socketIo = new VueSocketIO({
  //debug: false,
  connection: config.baseUrl,
  vuex: {
    store,
    actionPrefix: 'SOCKET_',
    mutationPrefix: 'SOCKET_'
  }
});
Vue.use(socketIo);

if (process.env.NODE_ENV === 'production') {
  register(`${process.env.BASE_URL}service-worker.js`, {
    ready() {
      console.log('App is being served from cache by a service worker.\n' + 'For more details, visit https://goo.gl/AFskqB')
    },
    registered() {
      console.log('Service worker has been registered.');
    },
    cached() {
      console.log('Content has been cached for offline use.');
    },
    updatefound() {
      console.log('New content is downloading.');
    },
    updated() {
      console.log('New content is available; please refresh.');
    },
    offline() {
      console.log('No internet connection found. App is running in offline mode.');
    },
    error(error) {
      console.error('Error during service worker registration:', error);
    }
  });
}

Vue.use(MyLoadingPlugin);

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app');
