import Vue from 'vue';

interface AutoCloseF {
  (finish: boolean): void;
}

class AlertService {
  private options = {
    error: {
      icon: 'error',
      title: 'Error!.'
    },
    success: {
      icon: 'success',
      title: 'Success!'
    },
    info: {
      icon: 'info',
      title: 'Important!'
    }
  };

  private vuetify: any;

  injectVuetify(vuetify: any) {
    this.vuetify = vuetify;
  }

  errorRole(string: string, cb: Function) {
    Vue.swal.fire({
      icon: 'error',
      title: 'Oops...',
      html: string + '<br>Consider upgrading to Pro?',
      onClose: () => {
        cb(true);
      }
    })
  }

  error(string: string) {
    const options = { ...this.options.error, html: string };
    Vue.swal(options);
  }

  translate(text) {
    return this.vuetify.lang.t('$vuetify.alertS.' + text);
  }

  loading(string: string) {
    const options = {
      title: this.translate('loading') + '...',
      html: string,
      allowOutsideClick: false
    };
    Vue.swal(options);
    Vue.swal.showLoading();
  }

  success(string: string) {
    const options = { ...this.options.success, html: string };
    Vue.swal(options);
  }

  info(string: string) {
    const options = { ...this.options.info, html: string };
    Vue.swal(options);
  }

  autoClose(msg: string, cb: AutoCloseF) {
    let timerInterval: any;
    Vue.swal
      .fire({
        title: msg,
        html: 'I will close in <b></b> milliseconds.',
        timer: 2000,
        timerProgressBar: true,
        allowOutsideClick: false,
        onBeforeOpen: () => {
          Vue.swal.showLoading();
          timerInterval = setInterval(() => {
            const content = Vue.swal.getContent();
            if (content) {
              const b = content.querySelector('b');
              if (b) {
                b.textContent = Vue.swal.getTimerLeft();
              }
            }
          }, 100);
        },
        onClose: () => {
          clearInterval(timerInterval);
        }
      })
      .then((result: any) => {
        /* Read more about handling dismissals below */
        cb(true);
        /* if (result.dismiss === Vue.swal.DismissReason.timer) {
          cb(true);
        } */
      })
  }
}

const alertS = new AlertService();
export default alertS;
