<template>
  <div>
    <v-navigation-drawer id="sideBarMain" color="sideBarBlue" v-model="isActive" app clipped>
      <SideBar></SideBar>
    </v-navigation-drawer>

    <NavBar title="CheckLeaked.cc" :isActive.sync="isActive"></NavBar>

    <v-main id="v-main" v-bind:class="$router.currentRoute.name" class="slotLayout">
      <div class="h-100">
        <slot></slot>
      </div>
      <footerLeak />
    </v-main>
  </div>
</template>

<script>
import footerLeak from '@/components/Footer/NewFooter.vue';
import NavBar from '@/components/NavBar/NewNavBar.vue';
import SideBar from '@/components/SideBar/NewSideBar.vue';

export default {
  props: {
    source: String
  },
  components: {
    NavBar,
    SideBar,
    footerLeak
  },
  data: () => ({
    isActive: null
  }),
  created() {
    this.stopLoading();
  },
  methods: {
    stopLoading() {
      const appLoading = document.getElementById('loading-bg');
      if (appLoading) {
        appLoading.style.display = 'none';
      }
    },
    open() {
      this.isActive = true;
      const myDiv = document.querySelector('#sideBarMain .v-navigation-drawer__content');
      if (myDiv) myDiv.scrollTop = 9999999;
    },
    close() {
      this.isActive = false;
    }
  },
  beforeMount() {
    this.$vuetify.theme.dark = true;
  }
};
</script>

<style lang="scss">

body .v-main .v-main__wrap {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  min-height:calc(100vh - 64px);
}
.slotLayout .v-main__wrap {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

main.privacy,
main.terms {
  background-color: #01033d66;
}

body {
  .v-alert a {
    color:white;
    &:hover {
      opacity:0.8;
    }
  }
}
</style>
