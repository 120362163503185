import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import en from '../locale/en'
import es from '../locale/es'


Vue.use(Vuetify)

const locales: any = { es, en };

export default new Vuetify({
  lang: {
    locales,
    current: 'en'
  },
  defaultAssets: {
    font: true,
    icons: 'mdi'
  },
  icons: {
    iconfont: 'mdi'
  },
  theme: {
    options: {
      customProperties: true
    },
    themes: {
      dark: {
        navBarBlue: '#373774',
        sideBarBlue: '#494ca2',
        darkColor: '#1E1E1E'
      },
      light: {
        navBarBlue: '#373774',
      }
    }
  }
});
