















































import alertS from '../../../services/AlertService/AlertService';
import rService from '../../../services/RequestService/RequestServiceSetup';

export default {
  data() {
    return {
      rules: {
        required: (value: string) => !!value || 'Required.',
        validate_length: (value: string) => value.length === 36 || '36 characters',
        validate_uuid4: (str: string) => {
          const regexExp = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
          return regexExp.test(str) || 'The bot token is not valid.'; // true
        },
      },
      is_valid: false,
      loading_login: false,
      loading_recover: false,
      user: {
        email: '',
        bot_token: '',
      },
      dialog: false,
      showPassword: false,
    };
  },
  methods: {
    t(text: string) {
      return this.$vuetify.lang.t('$vuetify.BotTokenAuth.' + text);
    },
    async login() {
      this.loading_login = true;
      const token: string = this.user.bot_token;
      const res = await rService.post('auth/bot_token', { token: token.toUpperCase() }, false, true);
      if (res.error) {
        this.loading_login = false;
        return alertS.error(res.error);
      }
      if (res.unique_id) {
        rService.setupUniqueIdHash(res.unique_id, res.session_token);
        this.$store.dispatch('authenticate', {
          access_token: 'bot-token',
          platform: 'general',
        });
      }
      this.loading_login = false;
      return;
    },
    is_email_valid(value: string) {
      const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return pattern.test(value);
    },
    async recover() {
      try {
        const email = this.user.email;
        if (!email) {
          throw new Error('Email cannot be empty');
        }
        const valid = this.is_email_valid(email);
        if (!valid) {
          throw new Error('Email is not valid');
        }
        this.loading_recover = true;
        const res = await rService.post('auth/bot_token/recover', { email }, false, true);
        if (res.error) {
          throw new Error(res.error);
        } else {
          alertS.success("Check your email. If you don't see it, check your promotions / spam folder.");
        }
        this.loading_recover = false;
      } catch (e) {
        this.loading_recover = false;
        alertS.error(e.message);
      }
    },
  },
};
