








import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'Cooldown',
  data() {
    return {
      showCooldown: false,
      cooldown: 0,
      valueDeterminate: 50,
      interval: null,
      timer: null,
    };
  },
  beforeDestroy() {
    if(this.timer) clearTimeout(this.timer);
    if(this.interval) clearInterval(this.interval);
  },
  methods: {
    ...mapActions(['setCooldown'])
  },
  computed: {
    cooldownOver100() {
      if (!this.cooldown || this.cooldown < 0) return 0;
      return (this.cooldown * 100) / (this.timerInfo.cooldown * 1000);
    },
    seconds() {
      return this.timerInfo.cooldown - Math.ceil(this.cooldown / 1000);
    },
    ...mapGetters(['activeCooldown', 'timerInfo', 'limitReachedAbs'])
  },
  watch: {
    'timerInfo.inCooldown'(newVal) {
      this.setCooldown({ status: newVal, resetTimeLeft: false });
    },
    activeCooldown(newVal) {
      if (newVal == true) {
        this.showCooldown = true;
        if (this.interval != null) {
          clearInterval(this.interval);
        }
        const timeLeft = this.timerInfo.timeLeft;
        let initialCooldown = this.timerInfo.cooldown;
        if (timeLeft) initialCooldown = timeLeft;
        this.cooldown = timeLeft ? (this.timerInfo.cooldown - timeLeft) * 1000 : 0;
        // this.cooldown = 3000;
        this.interval = setInterval(() => {
          this.cooldown += 1000;
        }, 1000);
        this.timer = setTimeout(() => {
          clearInterval(this.interval);
          this.setCooldown({ status: false, resetTimeLeft: false });
          this.showCooldown = false;
        }, initialCooldown * 1000);
      }
    }
  }
};
