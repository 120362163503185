


















































































import GoogleComponent from './LoginComponents/Google.vue';
import FacebookComponent from './LoginComponents/Facebook.vue';
import DiscordComponent from './LoginComponents/DiscordComponent.vue';
import TelegramComponent from './LoginComponents/TelegramComponent.vue';
import TwitterComponent from './LoginComponents/TwitterComponent.vue';
import LinkedinComponent from './LoginComponents/LinkedinComponent.vue';
import BotTokenComponent from './LoginComponents/BotTokenComponent.vue';
import YandexComponent from './LoginComponents/YandexComponent.vue';
import SlackComponent from './LoginComponents/SlackComponent.vue';
import EmailComponent from './LoginComponents/EmailComponent.vue';

import { mapActions, mapGetters } from 'vuex';
import config from '../../config/env';
export default {
  components: {
    BotTokenComponent,
    LinkedinComponent,
    GoogleComponent,
    FacebookComponent,
    DiscordComponent,
    TelegramComponent,
    TwitterComponent,
    YandexComponent,
    SlackComponent,
    EmailComponent,
  },
  data() {
    return {
      slack_auth_link: config.baseUrl + '/auth/slack',
      disclaimerAlert: false,
    };
  },
  mounted() {
    this.disclaimerAlert = localStorage.getItem('disclaimerAlertHide') === 'true' ? false : true;
  },
  computed: {
    dialog: {
      get() {
        return this.login_dialog;
      },
      set(val: boolean) {
        this.setLoginDialog(val);
      },
    },
    ...mapGetters(['loggedIn', 'login_dialog']),
  },
  watch: {
    loggedIn(newVal) {
      if (newVal == true) this.dialog = false;
    },
  },
  methods: {
    ...mapActions(['setLoginDialog']),
    disclaimerChange(val: boolean) {
      if (!val) {
        localStorage.setItem('disclaimerAlertHide', 'true');
      }
    },
    open() {
      localStorage.path = window.location.pathname;
      this.dialog = true;
    },
    close() {
      localStorage.path = '';
      this.dialog = false;
    },
    t(text: string) {
      return this.$vuetify.lang.t('$vuetify.DisclaimerLogin.' + text);
    },
    tL(text: string) {
      return this.$vuetify.lang.t('$vuetify.Login.' + text);
    },
  },
};
