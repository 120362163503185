








const pathAudio = require('@/assets/audio/tick.mp3');
const audio = new Audio(pathAudio);

export default {
  name: 'SnackCopy',
  data() {
    return {
      snackbar: true,
      timeout: 750,
      timer:null
    };
  },
  beforeDestroy() {
    if(this.timer) clearTimeout(this.timer);
  },
  watch: {
    text(newVal) {
      audio.play();
    }
  },
  computed: {
    text() {
      return this.$store.state.copied;
    }
  },
  mounted() {
    audio.play();
    this.timer = setTimeout(() => {
      this.$store.state.copied = '';
    }, this.timeout);
  }
};
