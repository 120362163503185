








export default {
  name: 'Popup',
  props: {},
  components: {
    'vs-popup': () => import('@/components/Popup/VSPopup.vue')
  },
  created() {
    this.$store.subscribe((mutation, state) => {
      if (mutation.type == 'openPopup') {
        this.popupActive = true;
        this.popup = {
          title: this.$vuetify.lang.t(`$vuetify.Popup.${mutation.payload}.title`),
          message: this.$vuetify.lang.t(`$vuetify.Popup.${mutation.payload}.message`)
        };
      }
    });
  },
  data() {
    return {
      popup: {
        title: '',
        message: ''
      },
      popupActive: false
    };
  },
  methods: {}
};
