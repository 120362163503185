export function isAndroid() {
  if (!navigator || !navigator.userAgent) return false;
  var ua = navigator.userAgent.toLowerCase();
  var isAndroid = ua.indexOf('android') > -1;
  if (isAndroid) {
    return true;
  } else {
    return false;
  }
}
