




import alertS from '../../../services/AlertService/AlertService';
import rService from '../../../services/RequestService/RequestServiceSetup';

export default {
  name: 'LoginEventApp',
  data() {
    return {
      website: document.location.origin,
      discord: 'discord'
    };
  },
  methods: {
    setToken(token: string) {
      this.$cookies.set('discordToken', token);
    },
    getToken() {
      return this.$cookies.get('discordToken');
    },
    destroyToken() {
      this.$cookies.remove('discordToken');
    },
    redirectAfterLogin() {
      if (localStorage.path) {
        const path = '' + localStorage.path;
        localStorage.path = '';
        this.$router.push(path).catch();
      }
    },
    async getUserInfo() {
      const token = this.getToken();
      if (token) {
        this.$store.dispatch('authenticate', {
          access_token: token,
          platform: this.discord
        });
        this.redirectAfterLogin();
      } else {
        this.logout();
      }
    },
    async loginDiscord() {
      const urlParams = new URLSearchParams(window.location.search);
      const code = urlParams.get('code');
      if (code) {
        const website = this.website;
        const postBody = { code, website };
        const res = await rService.post(`${this.discord}/get_token`, postBody, true, false);
        if (res.error) {
          this.logout();
          return alertS.error('Error login Discord - Code Error');
        }
        if (res.access_token) {
          const query = Object.assign({}, this.$route.query);
          delete query.code;
          this.$router.replace({ query });
          this.setToken(res.access_token);
          this.getUserInfo();
        }
      }
    },
    async logout() {
      this.destroyToken();
      this.$store.commit('destroyUserInfo', 'general');
    },
    async loginGeneral() {
      const urlParams = new URLSearchParams(window.location.search);
      const code = urlParams.get('codeG');
      if (code) {
        const postBody = { code };
        const res = await rService.post('general/code_auth', postBody, true, false);
        const query = Object.assign({}, this.$route.query);
        delete query.codeG;
        this.$router.replace({ query });

        if (res.error) {
          return alertS.error('Error login Telegram - Code Error');
        }
        if (res.unique_id) {
          rService.setupUniqueIdHash(res.unique_id, res.session_token);
          this.$store.dispatch('authenticate', {
            access_token: 'telegram-token',
            platform: 'general'
          });
          this.redirectAfterLogin();
        } else {
          this.logout();
        }
      }
    }
  },
  beforeMount() {
    // alert('CREATED');
    this.loginDiscord();
    this.loginGeneral();
  }
};
