import { en } from 'vuetify/src/locale';

export default {
  ...en,
  Login: {
    email: 'Login / Register with E-mail'
  },
  DisclaimerLogin: {
    t1: 'Third party login works with different accounts.',
    t2: "If you register or log in with google it will not be the same account as if you did it with twitter.",
  },
  Locales: {
    en: 'English',
    es: 'Spanish'
  },
  NoticeInfo: {
    title: 'Are you sure?',
    text: "This alert won't be displayed again on this device",
    button: 'Confirm',
    titleS: 'Confirmed!',
    textS: 'Alert has been removed from this device'
  },
  ModalLeak: {
    title: 'Set LeakCheck.net Api Key',
    message_1_0: 'Starting from 1st January you will have to link a',
    message_1_1: 'api key to use our service. Tweet explaining this issue',
    here: 'HERE',
    message_2_0: 'Before saving add the ip',
    message_2_1: 'to your authorized ips (whitelist)',
    copy: 'Copy IP',
  },
  Settings: {
    Settings: 'Settings',
    CardTitle: 'Menu Items (Show / Hide)'
  },
  Notice:
    'Review us at https://www.trustpilot.com/evaluate/checkleaked.cc, we appreciate your feedback',
  CommonNotice: 'We have no relation with leakcheck.io besides using their api to provide part of our service',
  botLinks: {
    doc: 'API Documentation in Swagger. We use the Bot Token as Api Key, keep it safe',
    accessFirst: 'You have to login first in order to access your bot token',
    loading: 'Loading...'
  },
  announce: {
    close: 'Close',
    closeNotShowAgain: 'Close and not show again',
    announce: "Our android app is available in Google PlayStore and it's free!",
    download: 'Download Now'
  },
  alertS: {
    loading: 'Loading'
  },
  usesLeft: {
    creditsAvailable: 'credits available',
    hoursUntilReset: 'hours until reset'
  },
  Language: {
    select: 'Select a language'
  },
  Router: {
    destroy: 'Destroy account',
    trackingData: 'Tracking Data',
    passwordStrength: "Verify your password strenght",
    whatsappChecker: "WhatsApp Checker",
    googleChecker: 'Google Checker',
    bots: 'Bots',
    tools: 'Tools',
    updates: 'Updates',
    dehashedCheck: 'Dehashed Search',
    searchPastes: "Search Pastes",
    trackingHistory: 'Tracking History',
    tracking: 'Tracking System',
    ChromeExtension: 'Chrome Extension',
    Blogs: 'Blogs',
    botLinks: 'Api / Bot Links',
    redeem: 'Redeem Key',
    apk: 'APK Redirection...',
    faq: 'FAQ',
    myEntries: 'My Searches',
    myEntriesDehashed: 'My searches Dehashed',
    myEntriesExp: 'My searches Experimental',
    terms: 'Terms and Conditions',
    privacy: 'Privacy Policy for CheckLeaked.CC',
    contact: 'Contact us',
    pricing: 'Pricing'
  },
  Redeem: {
    redeemAutomatic: 'Automatic redeem',
    pricing: 'Pricing',
    loginToRedeem: 'Login to redeem key',
    checkingKey: 'Verifying key..',
    key: 'Key',
    doYouAgree: 'Do you agree with our terms and services?',
    redeem: 'Redeem Key',
    clearFields: 'Clear Fields',
    charLong: 'Key must be at least 36 characters long',
    required: 'Key is required',
    mustAgree: 'You must agree with our term and conditions to continue!'
  },
  Home: {
    wildcardLabel: 'Enable wildcard search',
    linkRequest: 'You need to link your <platform> account in ',
    noDate: 'No date',
    checkDehashed: 'Check in Dehashed',
    checkExperimental: 'Check in Experimental',
    foundBreaches: 'Found ${found} breaches<br>${foundWithPass} of them with exposed passwords',
    checkingForLeaks: 'Checking for leaks',
    downloadNoSources: 'Download',
    downloadWithSources: 'Download (With Sources)',
    downloadExcel: 'Download (Excel)',
    title: 'Is my account leaked?',
    searchHolder: 'Select a Type',
    type: 'Type',
    enter: 'Enter a',
    onlyLeakCheck: {
      Name: 'Only LeakCheck'
    },
    placeHolderLink: {
      email: 'E-mail',
      mass: 'Keyword',
      login: 'Username',
      pass_email: 'E-mail Password',
      domain_email: 'Domain',
      pass_login: 'User Password',
      phone: 'Phone',
      pass_phone: 'Password',
      mc: 'MC Username',
      pass_mc: 'Password',
      hash: 'hash',
    },
    types: {
      free: 'Free Lookup',
      email: 'E-mail',
      mass: 'E-mail address by keyword (keyword@*.*)',
      login: 'Username',
      pass_email: 'E-mail by password',
      domain_email: 'E-mail by domain',
      pass_login: 'Username by password',
      phone: 'Phone (CIS Only)',
      pass_phone: 'Phone by password (CIS Only)',
      mc: 'Minecraft username (CIS Only)',
      pass_mc: 'Minecraft by password (CIS Only)',
      hash: 'E-mail by hash (md5, sha1, sha256)',
    },
    ref: {
      leakCheck: 'Only LeakCheck',
      hibp: 'Only HIBP',
      hibpLeak: 'In HIBP and LeakCheck',
      unknown: 'Unknown'
    },
    search: 'Search',
    reset: 'Reset',
    headers: {
      lastBreach: 'Last Breach',
      user: 'User',
      email: 'Email',
      password: 'Password',
      sources: 'Breaches',
      dehashed: 'Check in DeHashed.com'
    },
    validation: {
      required: 'Required.',
      maxChars: 'Max 200 characters',
      minChars: 'Min 4 characters',
      invalidEmail: 'Invalid e-mail.',
      invalidNoEmail: "This field shouldn't be an email",
      invalidDomain: 'This field is not a valid domain'
    },
    find: 'Find',
    findEmailPass: 'Find Email / Password',
    findByBreach: 'Filter by Breaches',
    success: 'Your account seems to be safe'
  },
  Popup: {
    deleteEntries: {
      title: 'Important',
      message: 'In order to delete your entries from the search engine please contact <a href="https://leakcheck.io/?ref=206648" >LeakCheck.io</a>. We only show the results from their api comparing it to HIBP one'
    }
  },
  Alert: {
    title: 'Are you sure?',
    description: 'This will cost you 1 credit if there is any breach',
    notDisplayed: "This message won't be displayed again.",
    notShowAgain: "Don't show this again",
    regret: 'I regret'
  },
  SideBar: {
    tools: 'Tools',
    passwordStrength: "Is your password strong enough?",
    whatsappChecker: "Get Whatsapp Profile Information",
    googleChecker: 'Google Checker',
    bots: 'Bots',
    updates: 'Updates',
    dehashedCheck: 'Dehashed Search',
    experimentalCheck: 'Experimental Search',
    searchPastes: "(New) Search Pastes",
    tracking: 'Tracking',
    ChromeExtension: 'Chrome Extension',
    Blogs: 'Blogs',
    botLinks: 'Api / Bot Links',
    downloadApk: 'Download APK',
    home: 'Home',
    faq: 'FAQ (Most Frequent Questions)',
    redeem: 'Redeem',
    language: 'Language',
    checkYourAccount: 'Check your Account',
    breaches: 'Breaches',
    contact: 'Contact',
    deleteMyEntries: 'Delete my entries',
    pricing: 'Pricing',
    myEntries: 'My Searches',
    myEntriesDehashed: 'My searches Dehashed',
    myEntriesExp: 'My searches Experimental'
  },
  Pricing: {
    notRecommended: 'Not recommended if you are going to change your plan',
    redeemAutomatic: 'Instant redeem',
    ifChecked: 'If checked the key will be automatically redeemed in this account',
    alreadyHave: 'You already have a Free or even better subscription',
    redeemKey: 'Redeem key',
    processingFree: 'Processing your Free Subscription...',
    pay: 'Purchase key',
    current: 'Current Plan (Extension key)',
    tab_unlock: 'Unlock passwords (experimental / dehashed)',
    tab_unlock_exp: 'Unlock passwords (only experimental)',
    free: 'Get Free',
    tab1: 'checks per day',
    tab2: 'Email Search',
    tab3: 'User Search',
    tab4: 'Search by Domain',
    tab5: 'Search by Password',
    tab6: 'Keyword Search',
    tab7: 'Download Results',
    upcoming: 'Access to upcoming features',
    currentPlan: 'Current Plan (Extend)',
    extend: 'Extend',
  },
  Plans: {
    unverified: 'No plan',
    free: 'Free Plan',
    plus: 'Plus Plan',
    pro: 'Pro Plan'
  },
  delete: 'Delete',
  close: 'Close',
  save: 'Save',
  Landing: {
    text: 'Protect your account, discover its leaks, avoid using insecure passwords. Secure your company and your person, know the passwords and information that hackers already have about you. ',
    orderNow: 'Order now',
    getTouch: 'Get in touch',
    boxes: {
      box_1: {
        title: 'Free',
        text: '2 free daily check-ups. Search by User / Email available. Complete transparency. Avoid making your sensitive information easily accessible '
      },
      box_2: {
        title: 'Custom plans',
        text: 'You can request your custom plan. Search by domain / keywords and other available features'
      },
      box_3: {
        title: 'We connect services',
        text: 'Using the power of LeakCheck.io and HIBP we find the breaches in which your account has been compromised, those where your password has been exposed along with other sensitive data that may put your Internet security at risk'
      },
      box_4: {
        title: 'Reliability',
        text: "Using LeakCheck's 2 years of backup plus HIBP's years of track record we provide the most up-to-date information regarding leaks. Some that are not even currently in HIBP but are currently public"
      },
      box_5: {
        title: 'You help us improve',
        text: 'Access to our premium services will help us maintain this service and connect it with more external services such as Dehashed (Currently we help to remove your entry but it is not possible to verify the exposed information)'
      },
      box_6: {
        title: 'What are you waiting for?',
        text: 'Hundreds of accounts are hacked daily, avoid being next. Avoid using any type of password or similar that you find in our search system '
      }
    },
    unlimited: 'Unlimited for everyone',
    count: 'You can always count on us.',
    notConvinced: 'Still not convinced?',
    contactUs: 'Contact us',
    tutorial: 'Tutorial',
    usersConnected: 'users connected',
    searchs: 'searches done',
    compromised: ' compromised accounts'
  },
  Contact: {
    Discord: {
      title: 'Discord Server',
      description: 'Our current support server ',
      extendedDescription: 'Server opened with the intention to provide help to our customers / users.'
    },
    Facebook: {
      title: 'Facebook Page',
      description: 'Our next new social media',
      extendedDescription: 'Already available'
    },
    Twitter: {
      title: 'Twitter Page',
      description: 'Our social media for updates',
      extendedDescription: 'Already available'
    },
    Mail: {
      title: 'Send us an email',
      description: 'Our email, short reply time.',
      extendedDescription: 'Already available'
    },
    Linkedin: {
      title: 'Follow us in linkedin',
      description: 'Our official linkedin page',
      extendedDescription: 'Already available'
    },
    share: 'Share',
    join: 'Join now',
    mail_us: 'Email us',
    copy: 'Copy',
    notAvailable: 'Not still available. Coming soon..'
  },
  Chat: {
    fMessage: {
      text: 'Hi, we are under construction, you can talk with an agent or join our Discord Server (Faster support)',
      suggestions: {
        human: 'Talk with an agent',
        discord: 'Join discord'
      }
    },
    agentWillCommunicate: 'One of our agents will send you a message as soon as its available'
  },
  Video: {
    tutorial_title: 'Welcome to CheckLeaked.cc',
    tutorial_text:
      "To facilitate your experience we leave you this video tutorial. In case you want to review the steps you can see it again by clicking on the 'Tutorial' button on the Main page",
    buttons_thanks: "Thanks, it's clear to me",
    buttons_problem: 'I found this a bit confusing',
    startLogin: 'Start by logging in with one of your Social Networks in the Left Side Panel',
    finisHelp: "You can search for leaks by clicking in 'Search Leaks', any problem you can always ask us by clicking in the chat bubble"
  },
  faq: [
    {
      title: 'How does this service works?',
      description:
        'We get the most recent public leaks from databases thanks to the use of <a href="https://leakcheck.net">https://leakcheck.net</a>, <a href="https://haveibeenpwned.com/">https://haveibeenpwned.com/</a> and <a href="http://dehashed.com/">http://dehashed.com/</a> search engines. We combine all of them into one single service to provide you the most recent and accurate leaks linked to an email / username / password / domain. All info exposed in the service is provided by at least one of those websites.'
    },
    {
      title: 'How do I use it?',
      description: 'Check our tutorial below: ',
      video: '@/views/Landing/tutorial/tutorial2.mp4'
    },
    {
      title: 'Is this service free?',
      description:
        'Yes, you can look up for 2 leaks per day (email / username) with its respective passwords and sources. If you want to help us you can check our exclusive premium plans at <a href="https://checkleaked.cc/pricing">https://checkleaked.cc/pricing</a>'
    },
    {
      title: 'Do you have wildcard search?',
      description:
        'Yes, plus / pro users have access to complex wildcard search. You can use them with the Dehashed Search Free Lookup feature. Example: email:pers*n&domain=gmail.com'
    }
  ],
  AlertDonation: {
    considerDonating: 'Nuestros planes'
  },
  ChromeExtension: {
    title: 'We adapt to your needs!',
    text: `With our new chrome extension you can access our services from any browser tab.
    Being able to verify if your account has been compromised without having to enter our site, facilitating the use of our application.
    Remember to always be alert, you never know when your account could be compromised.`,
    download: 'Download Here',
    doubts: 'Doubts?',
    textDoubts: 'Our simple tutorial will explain how to make the most of our extension'
  },
  Tracking: {
    noData: 'No data available',
    noTracking: 'No entries in register',
    login: 'User',
    email: 'Email',
    domain: 'Domain',
  },
  Bots_discord: {
    name: 'Discord Bot',
    description: 'Invite our bot to your server, link it to your account and have instant access to our search engines using simple commands',
    joinText: 'Invite Link'
  },
  Bots_telegram: {
    name: 'Telegram Bot',
    description: 'Talk with our bot, access to our search engine with one simple command from your telegram mobile or desktop app',
    joinText: 'Talk with our bot'
  },
  Bots_slack: {
    name: 'Slack Bot',
    description: 'Talk with our bot, access to our search engine with one simple command from your slack mobile or desktop app',
    joinText: 'Add bot to your workspace'
  },
  myEntries: {
    removeDups: 'Remove duplicates'
  },
  PlansAlerts: {
    text1: 'you reached your max amount of generations',
    text2: 'Need to check more accounts?',
    text3: 'Consider getting one of our plans',
    text4: 'Get a Plan',
    text5: 'Help us improve with your review'
  },
  BotTokenAuth: {
    title: 'Login with Bot Token',
    your_email: 'Your email',
    your_bot_token: 'Your bot token',
    recover: 'Recover',
    login: 'Login'
  },
  whatsappChecker: {
    0: 'Available Features',
    1: 'Check the number has a Whatsapp Profile',
    2: 'Get Public Profile Picture',
    3: 'Get About',
    4: "Check if it's a business",
    5: "Check if it's enterprise",
    6: 'Give it a try'
  }
};
