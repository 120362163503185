
const socketModule = {
  namespaced: true,
  state: () => ({
    connected: '',
    usersConnected: 0,
    pwn_count: 0,
    search_count: 0,
    initializeBotLinks: false,
    socket_logout: false
  }),
  mutations: {
    SOCKET_initializeBotLinks: function(state, msg) {
      state.initializeBotLinks = !state.initializeBotLinks;
    },
    SOCKET_connected: function(state, msg) {
      state.connected = msg;
    },
    SOCKET_users_connected: function(state, msg) {
      state.usersConnected = msg;
    },
    SOCKET_pwn_count: function(state, msg) {
      state.pwn_count = msg;
    },
    SOCKET_search_count: function(state, msg) {
      state.search_count = msg;
    },
    SOCKET_logout: function(state, msg) {
      state.socket_logout = true;
    },
    SOCKET_alert: function(state, msg) {
      
    }
  },
  actions: {
    reset_logout_socket({ commit, state }) {
      state.socket_logout = false;
    },
    regenerate_bot_links({ commit, state }) {
      state.initializeBotLinks = !state.initializeBotLinks;
    }
  },
  getters: {
    initializeBotLinks: state => {
      return state.initializeBotLinks;
    },
    socket_logout: state => {
      return state.socket_logout;
    },
    connected: state => {
      return state.connected;
    },
    users: state => {
      return state.usersConnected;
    },
    pwn_count: state => {
      return state.pwn_count;
    },
    search_count: state => {
      return state.search_count;
    }
  }
};

export default socketModule;
