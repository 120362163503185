






import config from '../../../config/env';
export default {
  data() {
    return {
      baseUrl: config.baseUrl
    };
  },
  methods: {
    login() {
      window.location.href = this.baseUrl + '/auth/twitter';
    }
  }
};
