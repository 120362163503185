import axios, { AxiosInstance } from 'axios';
import alertS from '../AlertService/AlertService';
declare const grecaptcha: any;

export class RequestService {
  private axios: AxiosInstance;
  private recaptchaKey: string;
  private header = {
    'Public-Token': 'eduairApp',
    'Access-Token': '',
    'unique-id-hash': '',
    session_token: ''
  };

  setupAccessToken(access_token: string) {
    this.header['Access-Token'] = access_token;
  }

  constructor(baseUrl: string, recaptchaKey: string) {
    this.recaptchaKey = recaptchaKey;
    this.axios = axios.create({
      baseURL: baseUrl + "/",
      //withCredentials: true,
    })
  }

  setupUniqueIdHash(unique_id_hash: string, session_token: string) {
    this.header['unique-id-hash'] = unique_id_hash;
    this.header['session-token'] = session_token;
  }

  destroyAccessToken() {
    delete this.header['Access-Token'];
  }

  destroyUniqueIdHash() {
    delete this.header['unique-id-hash'];
    delete this.header['session-token'];
  }

  private async postCaptcha(url: string, body: any) {
    return new Promise(resolve => {
      grecaptcha.ready(() => {
        grecaptcha.execute('6LcTNcMUAAAAALfLcgD0y-A5e6t4vefcFNdeH5ED', { action: 'homepage' }).then((token: string) => {
          body['g-recaptcha-response'] = token;
          resolve(this.postReq(url, body));
        })
      });
    })
  }

  private postReqFile(url: string, body: any) {
    return this.axios({
      method: 'post', // you can set what request you want to be
      url: url,
      data: body,
      responseType: 'arraybuffer',
      headers: this.header
    })
      .then((response: { data: any }) => {
        return response.data;
      })
      .catch((error: { message: any }) => {
        return { error: error.message };
      })
  }

  private postReq(url: string, body: any) {
    return this.axios({
      method: 'post', // you can set what request you want to be
      url: url,
      data: body,
      headers: this.header
    })
      .then((response: { data: any }) => {
        return response.data;
      })
      .catch((error: { message: any }) => {
        return { error: error.message };
      })
  }

  private getReq(url: string) {
    return this.axios({
      method: 'get', // you can set what request you want to be
      url: url,
      headers: this.header
    })
      .then((response: { data: any }) => {
        return response.data;
      })
      .catch((error: { message: any }) => {
        return { error: error.message };
      })
  }

  public async postFile(model: string, body: any, disableAlert?: boolean, captcha?: boolean): Promise<any> {
    let res: any;
    res = await this.postReqFile(model, body);
    if (!disableAlert && res.error) alertS.error(res.error);
    return res;
  }


  public async post(model: string, body: any, disableAlert?: boolean, captcha?: boolean): Promise<any> {
    let res: any;
    if (captcha) {
      res = await this.postCaptcha(model, body);
    } else {
      res = await this.postReq(model, body);
    }
    if (!disableAlert && res.error) alertS.error(res.error);
    return res;
  }

  public async get(model: string, disableAlert?: boolean): Promise<any> {
    const res = await this.getReq(model);
    if (!disableAlert && res.error) alertS.error(res.error);
    return res;
  }
}
