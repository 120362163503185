




import alertS from '../services/AlertService/AlertService';
import rService from '../services/RequestService/RequestServiceSetup';
export default {
  methods: {
    destroy_req() {
      this.$swal
        .fire({
          title: 'An email will be sent with instructions to destroy your account.',
          showCancelButton: true,
          confirmButtonText: 'Okay',
        })
        .then(async (result) => {
          /* Read more about isConfirmed, isDenied below */
          if (result.isConfirmed) {
            const res = await rService.post('user/destroy/request', {});
            if (res && res.success) {
              alertS.success(res.success);
            }
          }
        });
    },
  },
};
