









import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'Snack',
  data() {
    return {
      timeout: 100000
    };
  },
  methods: {
    ...mapActions(['destroySnack'])
  },
  computed: {
    ...mapGetters(['snackbar'])
  },
  beforeMount() {}
};
