import { es } from 'vuetify/src/locale';

export default {
  ...es,
  Login: {
    email: 'Login / Registro con E-mail'
  },
  DisclaimerLogin: {
    t1: 'El inicio de sesión de terceros funciona con diferentes cuentas.',
    t2: "Si te registras o inicias sesión con google no será la misma cuenta que si lo haces con twitter.",
  },
  Locales: {
    en: 'Inglés',
    es: 'Español'
  },
  NoticeInfo: {
    title: '¿Estás seguro?',
    text: 'Está alerta no se mostrará más en este dispositivo',
    button: 'Confirmar',
    titleS: 'Confirmado!',
    textS: 'La alerta ha sido eliminada de este dispositivo'
  },
  ModalLeak: {
    title: 'Configurar LeakCheck.net Api Key',
    message_1_0: 'A partir del 1 de enero, deberá vincular una clave api de',
    message_1_1: 'para utilizar nuestro servicio. Tweet explicando este problema',
    here: 'AQUÍ',
    message_2_0: 'Antes de guardar, agregue la ip',
    message_2_1: 'a tus ips autorizadas (lista blanca)',
    copy: 'Copiar IP'
  },
  Notice:
    'Deja tu reseña en https://www.trustpilot.com/evaluate/checkleaked.cc, lo apreciamos muchísimo',
  CommonNotice: 'No tenemos ninguna relación con leakcheck.io aparte de utilizar su api comercial para proveer parte nuestro servicio.',
  botLinks: {
    doc: 'Documentación de la API en Swagger. Usamos el Bot Token como Api Key, manténgalo a salvo',
    accessFirst: 'Debes iniciar sesión para acceder a tu token de autorización del bot',
    loading: 'Cargando...'
  },
  Settings: {
    Settings: 'Ajustes',
    CardTitle: 'Menú (Mostrar / Ocultar)'
  },
  usesLeft: {
    creditsAvailable: 'créditos disponibles',
    hoursUntilReset: ' horas para el reset'
  },
  announce: {
    close: 'Cerrar',
    closeNotShowAgain: 'Cerrar y no mostrar de nuevo',
    announce: 'Nuestra apk para android está disponible en la PlayStore y es totalmente gratis!',
    download: 'Descargar ahora'
  },
  alertS: {
    loading: 'Cargando'
  },
  Language: {
    select: 'Selecciona un idioma'
  },
  Router: {
    destroy: 'Destruir cuenta',
    trackingData: 'Información Tracking',
    passwordStrength: "Verifica si tu contraseña es segura",
    whatsappChecker: "Obtén información del perfil de un número de WhatsApp",
    googleChecker: 'Google Checker',
    bots: 'Bots',
    tools: 'Herramientas',
    updates: 'Actualizaciones',
    dehashedCheck: 'Búsqueda Dehashed',
    searchPastes: "Buscar pastes",
    trackingHistory: 'Historial de Tracking',
    tracking: 'Tracking System',
    ChromeExtension: 'Extensión de Chrome',
    Blogs: 'Blogs',
    botLinks: 'Api / Bot Links',
    redeem: 'Canjear',
    faq: 'FAQ',
    myEntries: 'Mis Búsquedas',
    myEntriesDehashed: 'Mis Búsquedas Dehashed',
    myEntriesExp: 'Mis Búsquedas Experimental',
    terms: 'Términos y Condiciones de Servicio',
    privacy: 'Políticas de Privacidad de CheckLeaked.cc',
    contact: 'Contáctanos',
    pricing: 'Precios'
  },
  Redeem: {
    loginToRedeem: 'Inicia sesión para canjear clave',
    checkingKey: 'Verificando clave..',
    key: 'Key',
    doYouAgree: '¿Estás de acuerdo con nuestros términos y servicios?',
    redeem: 'Canjear clave',
    clearFields: 'Limpiar campos',
    charLong: 'La clave debe tener al menos 36 caractéres',
    required: 'Clave es requerida',
    mustAgree: 'Debes estar de acuerdo con nuestros términos y condiciones antes de continuar!'
  },
  Home: {
    wildcardLabel: 'Habilitar búsqueda comodín',
    linkRequest: 'You need to link your <platform> account in ',
    noDate: 'Sin fecha',
    checkingForLeaks: "Buscando filtraciones...",
    checkDehashed: 'Check in Dehashed',
    checkExperimental: 'Check in Experimental',
    foundBreaches: 'Se han encontrado ${found} brechas<br>${foundWithPass} de ellas con contraseñas expuestas',
    downloadNoSources: 'Descargar',
    downloadWithSources: 'Descargar (Con Breachas)',
    downloadExcel: 'Descargar (Excel)',
    title: 'Se ha filtrado mi cuenta?',
    searchHolder: 'Selecciona tipo',
    type: 'Tipo',
    enter: 'Ingresa tú',
    onlyLeakCheck: {
      Name: 'Solo LeakCheck'
    },
    placeHolderLink: {
      email: 'E-mail',
      mass: 'Palabra Clave',
      login: 'Usuario',
      pass_email: 'Contraseña de E-mail',
      domain_email: 'Dominio',
      pass_login: 'Contraseña de Usuario',
      phone: 'Teléfono',
      pass_phone: 'Contraseña',
      mc: 'Usuario Minecraft',
      pass_mc: 'Contraseña Minecraft',
      hash: 'Hash'
    },
    types: {
      free: 'Free Lookup',
      email: 'E-mail',
      mass: 'E-mail por palabra clave (clave@*.*)',
      login: 'Usuario',
      pass_email: 'E-mail por contraseña',
      domain_email: 'E-mail por dominio',
      pass_login: 'Usuario por contraseña',
      phone: 'Phone (Solo CIS)',
      pass_phone: 'Phone by password (Solo CIS)',
      mc: 'Minecraft username (Solo CIS)',
      pass_mc: 'Minecraft by password (Solo CIS)',
      hash: 'E-mail por hash (md5,sha1,sha256)'
    },
    ref: {
      leakCheck: 'Solo LeakCheck',
      hibp: 'Solo HIBP',
      hibpLeak: 'En HIBP y LeakCheck',
      unknown: 'Desconocido'
    },
    search: 'Buscar',
    reset: 'Reiniciar',
    headers: {
      lastBreach: 'Última Brecha',
      user: 'Usuario',
      email: 'Email',
      password: 'Contraseña',
      sources: 'Filtraciones',
      dehashed: 'Verificar en DeHashed.com'
    },
    validation: {
      required: 'Requerido.',
      maxChars: 'Máximo 200 cáracteres',
      minChars: 'Mínimo 4 cáracteres',
      invalidEmail: 'E-mail inválido',
      invalidNoEmail: 'Este campo no debería ser un E-mail',
      invalidDomain: 'Dominio inválido'
    },
    find: 'Buscar',
    findEmailPass: 'Buscar Email / Contraseña',
    findByBreach: 'Buscar por Filtración',
    success: 'Tu cuenta parece estar segura'
  },
  Popup: {
    deleteEntries: {
      title: 'Importante',
      message: 'Para borrar tus resultados en la búsqueda favor de contactar a  <a href="https://leakcheck.io/?ref=206648" >LeakCheck.io</a>. Nosotros solamente mostramos los resultados utilizando su api comercial'
    }
  },
  Alert: {
    title: '¿Estás seguro?',
    description: 'Esto te costará 1 crédito si hay alguna brecha',
    notDisplayed: 'Este mensaje no aparecerá nuevamente',
    notShowAgain: 'No mostrar de nuevo',
    regret: 'Me arrepiento'
  },
  SideBar: {
    tools: 'Herramientas',
    passwordStrength: "¿Contraseña segura?",
    whatsappChecker: 'WhatsApp Checker',
    googleChecker: 'Google Checker',
    bots: 'Bots',
    updates: 'Actualizaciones',
    dehashedCheck: 'Búsqueda Dehashed',
    experimentalCheck: 'Búsqueda Experimental',
    searchPastes: "(Nuevo) Buscar pastes",
    tracking: 'Tracking',
    ChromeExtension: 'Extensión de Chrome',
    Blogs: 'Blogs',
    botLinks: 'Api / Bot Links',
    downloadApk: 'Descargar APK',
    home: 'Inicio',
    faq: 'FAQ (Preguntas más frecuentes)',
    redeem: 'Canjear',
    apk: 'APK Redirección...',
    language: 'Idioma',
    checkYourAccount: 'Buscar filtraciones',
    breaches: 'Filtraciones',
    contact: 'Contacto',
    deleteMyEntries: 'Eliminar mis entradas',
    pricing: 'Precios',
    myEntries: 'Mis Búsquedas',
    myEntriesDehashed: 'Mis Búsquedas Dehashed',
    myEntriesExp: 'Mis Búsquedas Experimental'
  },
  Pricing: {
    notRecommended: 'No recomendado si se piensa cambiar el plan',
    redeemAutomatic: 'Canjeo automático',
    thisIsAGift: 'Esto es un regalo',
    ifChecked: 'Si se marca, la clave se canjeará automáticamente en esta cuenta',
    alreadyHave: 'Ya tienes una suscripción gratuita o aún mejor',
    pricing: 'Precios',
    redeemKey: 'Canjear clave',
    checkingForLeaks: 'Buscando filtraciones',
    processingFree: 'Procesando tu subscripción gratuita...',
    pay: 'Comprar Clave',
    tab_unlock: 'Desbloquear contraseñas (experimental / dehashed)',
    tab_unlock_exp: 'Desbloquear contraseñas (solo experimental)',
    free: 'Obtener Gratis',
    current: 'Plan Actual (Clave de amplicación)',
    tab1: 'búsquedas diarias',
    tab2: 'Búsqueda por email',
    tab3: 'Búsqueda por usuario',
    tab4: 'Búsqueda por dominio',
    tab5: 'Búsqueda por contraseña',
    tab6: 'Búsqueda por palabra clave',
    tab7: 'Descargar Resultados',
    upcoming: 'Acceso a avances exclusivos',
    currentPlan: 'Plan Actual (Extender)',
    extend: 'Extender',
  },
  Plans: {
    unverified: 'No plan',
    free: 'Free Plan',
    plus: 'Plus Plan',
    pro: 'Pro Plan'
  },
  delete: 'Borrar',
  close: 'Cerrar',
  save: 'Guardar',
  Landing: {
    text: 'Protege tu cuenta, descubre sus filtraciones, evite usar contraseñas inseguras. Asegura tu empresa y tu persona, conoce las contraseñas e información que los hackers ya tienen sobre ti.',
    orderNow: 'Ordena ahora',
    getTouch: 'Ponte en contácto',
    boxes: {
      box_1: {
        title: 'Gratis',
        text: '2 checkeos diarios gratis. Búsqueda por Usuario / Email disponibles. Completa transparencia. Evite que su información sensible sea de fácil acceso'
      },
      box_2: {
        title: 'Planes a medida',
        text: 'Puede solicitar su plan a medida. Búsqueda por dominio / palabras clave y otras características disponibles'
      },
      box_3: {
        title: 'Conectamos servicios',
        text:
          'Utilizando el poder de HIBP encontramos las breaches en las que su cuenta ha sido comprometida, aquellas donde su contraseña ha sido expuesta junto a otros datos sensibles que pueden poner en riesgo su seguridad en Internet'
      },
      box_4: {
        title: 'Confiabilidad',
        text:
          'Utilizando los 2 años de respaldo de CheckLeaked más los años de trayectoria de HIBP proveemos la información más actualizada con respecto a filtraciones. Algunas que incluso no se encuentran actualmente en HIBP pero son actualmente públicas'
      },
      box_5: {
        title: 'Nos ayudas a mejorar',
        text:
          'El acceso a nuestros servicios premium nos ayudará a mantener este servicio y conectarlo con más servicios externos tales como Dehashed (Actualmente ayudamos a remover tu entrada pero no es posible verificar la información expuesta)'
      },
      box_6: {
        title: '¿Qué esperas?',
        text: 'Cientos de cuentas son hackeadas diariamente, evita ser el siguiente. Evita utilizar cualquier tipo de contraseña o similiar que encuentres en nuestro sistema de búsqueda'
      }
    },
    unlimited: 'Ilimitado para todos',
    count: 'Siempre podrás contar con nosotros.',
    notConvinced: '¿Todavía no estás convencido?',
    contactUs: 'Contáctanos',
    tutorial: 'Tutorial',
    usersConnected: 'usuarios conectados',
    searchs: 'búsquedas realizadas',
    compromised: ' cuentas comprometidas'
  },
  Contact: {
    Discord: {
      title: 'Servidor de Discord',
      description: 'Nuestro servidor de soporte',
      extendedDescription: 'Servidor abierto con la intención de prooveer ayuda a nuestros clientes / usuarios'
    },
    Facebook: {
      title: 'Página de Facebook',
      description: 'Our next new social media',
      extendedDescription: 'Ya disponible'
    },
    Twitter: {
      title: 'Página de Twitter',
      description: 'Nuestra red social para actualizaciones',
      extendedDescription: 'Ya disponible'
    },
    Mail: {
      title: 'Envianos un e-mail',
      description: 'Nuestro email, respuesta más rápida',
      extendedDescription: 'Ya disponible'
    },
    Linkedin: {
      title: 'Siguenos en Linkedin',
      description: 'Nuestra página oficial de Linkedin',
      extendedDescription: 'Ya disponible'
    },
    share: 'Compartir',
    join: 'Unéte',
    mail_us: 'Contáctanos',
    copy: 'Copiar',
    notAvailable: 'Todavía no está disponible. Muy pronto...'
  },
  Chat: {
    fMessage: {
      text: 'Hola, estamos en construcción, puedes hablar con un agente o unirte a nuestro servidor de Discord (Soporte más ágil)',
      suggestions: {
        human: 'Hablar con un Agente',
        discord: 'Unirse a Discord'
      }
    },
    agentWillCommunicate: 'Uno de nuestros agentes se comunicará contigo apenas esté disponible'
  },
  Video: {
    tutorial_title: 'Bienvenido a CheckLeaked.cc',
    tutorial_text:
      "Para facilitar tu experiencie te dejamos este video tutorial. En caso de querer repasar los pasos puedes verlo nuevamente dando clic en el botón 'Tutorial' en la página Princial",
    buttons_thanks: 'Gracias, me ha quedado claro',
    buttons_problem: 'Me ha parecido un poco confuso',
    startLogin: 'Empieza por iniciar sesión con alguna de tus Redes Sociales en el panel Lateral Izquierdo',
    finisHelp: "Puedes buscar filtraciones dando click a 'Buscar Filtraciones', cualquier problema siempre puedes consultarlos, clickeando nuestra burbuja de chat"
  },
  faq: [
    {
      title: '¿Cómo funciona nuestro servicio?',
      description:
        'Obtenemos las filtraciones públicas más recientes de las bases de datos gracias al uso de <a href="https://leakcheck.net"> https://leakcheck.net </a>, <a href = "https: // haveibeenpwned .com / "> https://haveibeenpwned.com/ </a> y <a href="http://dehashed.com/"> http://dehashed.com/ </a> motores de búsqueda. Los combinamos en un solo servicio para proporcionarle las filtraciones más recientes y precisas vinculadas a un correo electrónico / nombre de usuario / contraseña / dominio. Toda la información expuesta en el servicio es proporcionada por al menos uno de esos sitios web'
    },
    {
      title: '¿Cómo puedo acceder a su servicio?',
      description: 'Mira nuestro tutorial debajo (Cualquiera consulta tienes nuestro chat en la parte inferior derecha de la pantalla): ',
      video: '@/views/Landing/tutorial/tutorial2.mp4'
    },
    {
      title: '¿Es este servicio gratuito?',
      description:
        'Sí, puede buscar 2 emails/usuarios por día (correo electrónico / nombre de usuario) con sus respectivas contraseñas y fuentes. Si desea ayudarnos, puede consultar nuestros planes premium exclusivos en <a href="https://checkleaked.cc/pricing"> https://checkleaked.cc/pricing </a>'
    },
    {
      title: 'Tienen búsqueda por wildcard?',
      description:
        'Sí, usuarios plus / pro tienen acceso a esta característica. Pueden usarse con la Búsqueda Dehashed / Free Lookup. Ejemplo: email:pers*n&domain=gmail.com'
    }
  ],
  AlertDonation: {
    considerDonating: 'Nuestros planes'
  },
  ChromeExtension: {
    title: 'Nos adaptamos a tus necesidades!',
    text: `Con nuestra novedosa extensión de chrome podrás acceder a nuestros servicios desde cualquier pantalla del navegador.
    Pudiendo verificar si tu cuenta ha sido comprometida sin necesidad de entrar a nuestro sitio, fácilitando el uso de nuestra aplicación.
    Recuerda estar siempre alerta, nunca sabes cuando tu cuenta podría ser vulnerada.`,
    download: 'Descarga Aquí',
    doubts: '¿Dudas?',
    textDoubts: 'Nuestro simple tutorial te explicará cómo aprovechar al máximo está extension'
  },
  Tracking: {
    noData: 'No hay datos disponibles',
    noTracking: 'No hay entradas en el registro',
    login: 'Usuario',
    email: 'Email',
    domain: 'Dominio',
  },
  Bots_discord: {
    name: 'Discord Bot',
    description: 'Invita el bot a tu servidor, vincúlalo a tu cuenta y accede a nuestra lista de comandos',
    joinText: 'Enlace de invitación'
  },
  Bots_telegram: {
    name: 'Telegram Bot',
    description: 'Habla con nuestro bot, accede a nuestro sistema de búsqueda con un simple comando desde tu aplicación web o móvil de telegram.',
    joinText: 'Habla con nuestro bot'
  },
  Bots_slack: {
    name: 'Slack Bot',
    description: 'Habla con nuestro bot, accede a nuestro sistema de búsqueda con un simple comando desde tu aplicación web o móvil de slack.',
    joinText: 'Agregar bot al workspace'
  },
  myEntries: {
    removeDups: 'Remover Duplicados'
  },
  PlansAlerts: {
    text1: 'Has alcanzado el máximo número de generaciones diarias',
    text2: '¿Necesitas verificar más cuentas?',
    text3: 'Considera uno de nuestros planes premium',
    text4: 'Obtener un plan',
    text5: 'Ayudanos con tu reseña a seguir mejorando'
  },
  BotTokenAuth: {
    title: 'Login con tu Bot Token',
    your_email: 'Tú email',
    your_bot_token: 'Tú Bot Token',
    recover: 'Recuperar',
    login: 'Iniciar sesión'
  },
  whatsappChecker: {
    0: 'Características disponibles',
    1: "Verificar si el número tiene un perfil de Whatsapp",
    2: "Obtener la foto de perfil pública",
    3: "Obtener la descripción",
    4: "Verificar si es un negocio",
    5: "Verificar si es una empresa",
    6: 'Pruébalo ahora'
  }
};
