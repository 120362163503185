















import { mapActions, mapGetters } from 'vuex';
export default {
  name: 'Announce',
  computed: {
    ...mapGetters(['sheetOpen']),
  },
  methods: {
    ...mapActions(['openSheet']),
    changeStatus(nowShowAgain: boolean): void {
      this.openSheet(!this.sheetOpen);
      if (nowShowAgain) {
        localStorage.notAdvertiseApk = true;
      }
    },
    t(item: any): any {
      return this.$vuetify.lang.t(`$vuetify.announce.${item}`);
    },
  },
};
