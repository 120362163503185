import router from '@/router'
import alertS from '@/services/AlertService/AlertService'
import rService from '@/services/RequestService/RequestServiceSetup'
import crypto from 'crypto'
import Vue from 'vue'
import VueCookies from 'vue-cookies'
import Vuex from 'vuex'
import socketModule from './socket'

Vue.use(Vuex)
Vue.use(VueCookies);

/**
 * Status
 * 0 - Logged out
 * 1 - Logged in
 * 2- Unknown
 */

const generalLogin = ['telegram', 'twitter', 'linkedIn']

const gPlatform = (platform: string) => {
  let p = platform + ''
  if (generalLogin.includes(platform)) {
    p = 'general'
  }
  return p;
};

interface PlatformState {
  [key: string]: number;
}

export default new Vuex.Store({
  state: {
    login_dialog: false,
    menuItems: null,
    infoData: null,
    copied: '',
    unique_id: '',
    enableParticles: true,
    loadingBar: false,
    help: false,
    sheet: false,
    activeCooldown: false,
    loading: true,
    navBarStatus: true,
    languagePopup: false,
    timer: {
      timeLeft: 0,
      inCooldown: false,
      resetSeconds: 0,
      current: 0,
      max: 0,
      cooldown: 999,
      downloadResult: false,
      allowedTypes: []
    },
    snackbar: {
      open: false,
      text: '',
      closeEnabled: false
    },
    popup: null as any,
    sideBarStatus: false as boolean,
    activeBreach: '' as string,
    userInfo: {
      unique_id: '' as string,
      user_email: '' as string,
      email: '' as string,
      user: '' as string,
      image: '' as string,
      platform: '' as string,
      token: '' as string,
      plan: '' as string,
      session_token: '' as string,
      bot_token: '' as string,
      expiration_date: '' as string,
      enable_email_tracking: false as boolean,
      enable_discord_tracking: false as boolean,
      unlock_role: false as boolean,
    },
    platformState: {
      google: 2,
      facebook: 2,
      discord: 2,
      general: 2
    } as PlatformState,
    toCheck: {
      info: '' as string,
      type: '' as string
    },
    safe: null as boolean | null,
    scrollDown: false as boolean
  },
  modules: {
    socket: socketModule
  },
  mutations: {
    updateUserInfo: function (
      state,
      {
        userInfo,
        status
      }: {
        userInfo: {
          unique_id: string;
          email: string;
          user_email: string;
          user: string;
          image: string;
          platform: string;
          token: string;
          plan: string;
          bot_token: string;
          session_token: string;
          expiration_date: string;
          enable_discord_tracking: boolean,
          enable_email_tracking: boolean,
          unlock_role: boolean,
        };
        status: number;
      }
    ) {
      state.userInfo = userInfo;
      const Tawk_API = (window as any).Tawk_API;
      if (userInfo.email && Tawk_API) {
        const key = '6d7c793b771ab42e25c09db81a5e2d1c47e1c720';
        let token = crypto.createHmac("sha256", key).update(userInfo.email).digest().toString('hex');
        const json = {
          'name': userInfo.user,
          'email': userInfo.email,
          'hash': token,
          'expiration-date': userInfo.expiration_date,
          'customer-status': userInfo.plan,
          'platform': userInfo.platform,
        };
        if (!Tawk_API.onLoaded) {
          Tawk_API.onLoad = function () {
            Tawk_API.setAttributes(json, function (error) {
              console.error(error);
            });
          }
        } else {
          Tawk_API.setAttributes(json, function (error) {
            console.error(error);
          });
        }
      }

      /** General Login Auth */
      const platform = gPlatform(userInfo.platform);
      state.platformState[platform] = 1;

      /** Update User Unique ID */
      const unique_id = userInfo.unique_id;
      const session_token = userInfo.session_token;
      if (unique_id && session_token) {
        Vue.$cookies.set('uniqueIdHash', unique_id);
        Vue.$cookies.set('session_token', session_token);
        rService.setupUniqueIdHash(unique_id, session_token);
      }
    },
    destroyUserInfo: function (state, platform: string | 'global' = 'global') {
      state.unique_id = ''
      if (platform == 'global') {
        const newState = JSON.parse(JSON.stringify(state.platformState));
        const resSearch: string[] | undefined = Object.keys(newState).filter(key => {
          return state.platformState[key] == 1 || state.platformState[key] == 2;
        })

        platform = gPlatform(state.userInfo.platform);

        if (resSearch != undefined && resSearch.includes(platform)) {
          resSearch.forEach(platform => {
            state.platformState[platform] = 0;
          })
        }
      } else {
        state.platformState[platform] = 0;
      }
      const allZero = (values: number[]) => values.every(v => v === 0);
      const values = Object.values(state.platformState);
      if (allZero(values)) {
        /** Destroy Access Token */
        rService.destroyAccessToken();
        Vue.$cookies.remove('discordToken');

        /** Destroy Unique ID Hash */
        rService.destroyUniqueIdHash();
        Vue.$cookies.remove('uniqueIdHash');
        Vue.$cookies.remove('session_token');

        /** Clean User Info */
        state.userInfo = {
          unique_id: '',
          email: '',
          user_email: '',
          user: '',
          image: '',
          platform: '',
          token: '',
          plan: '',
          bot_token: '',
          session_token: '',
          expiration_date: '',
          enable_email_tracking: false,
          enable_discord_tracking: false,
          unlock_role: false,
        };
        const meta = router.currentRoute.meta;
        if (meta && meta.requiresAuth) {
          router.push({ name: 'landing' }).catch();
        }
      }
    },
    updateCheckInput: function (state, email: string) {
      if (router.currentRoute && router.currentRoute.name != 'home') {
        router.push({
          name: 'home',
          params: {
            toSearch: email,
            typeSelected: 'email'
          }
        }).catch();
        state.toCheck.info = email;
        state.toCheck.type = 'email';
      }
    },
    setDarkMode: function (state, val) {
      return val;
    },
    updateSafe: function (state, safe: boolean) {
      state.safe = safe;
    },
    openPopup: function (state, popup: any) {
      state.popup = popup;
    },
    updateSideBarActive: function (state, status: boolean) {
      state.sideBarStatus = status;
    },
    openSideBar: function (state, status) {
      return true;
    },
    logout_instant: function (state, status) {
      return true;
    },
    logout: function (state, status) {
      return true;
    },
    closeSideBar: function (state, status) {
      return true;
    },
    breachPopup: function (state, Name: string) {
      state.activeBreach = Name;
    },
    infoData: function (state, data: any) {
      state.infoData = data;
    },
    finishHelp: function (state, Name) {
      return true;
    },
    setMenuItems: function (state, prop) {
      state.menuItems = prop;
    }
  },
  actions: {
    logout_instant({ commit, state }, value: boolean) {
      commit('logout_instant');
    },
    logout({ commit, state }, value: boolean) {
      commit('logout');
    },
    openLogin({ commit, state }, value: boolean) {
      state.login_dialog = true;
    },
    enableLanguagePopup({ commit, state }, value: boolean) {
      state.languagePopup = value;
    },
    async regenerateToken({ commit, state }) {
      const resTokenReg = await rService.get(`bot_token/${state.userInfo.platform}`);
      if (!resTokenReg.error) {
        state.userInfo.bot_token = resTokenReg;
      }
    },
    updateTrackingData({ state }, { enable_email_tracking, enable_discord_tracking }) {
      state.userInfo.enable_email_tracking = enable_email_tracking;
      state.userInfo.enable_discord_tracking = enable_discord_tracking;
    },
    updateUserEmail({ state }, user_email: string) {
      state.userInfo.user_email = user_email;
    },
    setUniqueId({ state }, uniqueId: string) {
      state.unique_id = uniqueId;
    },
    openSheet({ state }, status: boolean) {
      state.sheet = status;
    },
    copied({ state }, text: string) {
      state.copied = text;
    },
    loadStatus({ state }, status: boolean) {
      state.loadingBar = status;
    },
    setCooldown({ commit, state }, { status, resetTimeLeft = false }: { status: boolean; resetTimeLeft: boolean }) {
      if (resetTimeLeft) {
        state.timer.timeLeft = 0;
      }
      state.activeCooldown = status;
    },
    setLoading({ commit, state }, status: boolean) {
      state.loading = status;
    },
    setLoginDialog({ commit, state }, status: boolean) {
      state.login_dialog = status;
    },
    toggleNavBar({ commit, state }) {
      state.navBarStatus = !state.navBarStatus;
    },
    destroyUserInfo({ commit, state }) {
      commit('destroyUserInfo');
    },
    openSnack({ commit, state }, text) {
      state.snackbar = { open: true, text, closeEnabled: false };
    },
    destroySnack({ commit, state }) {
      state.snackbar = { open: false, text: '', closeEnabled: false };
    },
    async updatePlan({ commit, state }, { plan }) {
      state.userInfo.plan = plan;
    },
    updateScroll({ commit, state }, scroll) {
      state.scrollDown = scroll;
    },
    async setupTimer({ commit, state, dispatch }) {
      const { platform, token } = state.userInfo;
      if (platform && token) {
        const genInfo = await rService.get(`timer/${platform}`, true);
        if (genInfo.error) {
          const error = genInfo.error;
          if (error == 'No user' || error == 'Invalid Access Token') {
            dispatch('destroyUserInfo');
          }
        } else {
          state.timer = genInfo;
        }
      } else {
        return { error: 'Error' };
      }
    },
    async agreeFree({ commit, state }, { access_token, platform }) { },
    async updateUserInfo({ commit, state, dispatch }) {
      try {
        const platform = gPlatform(state.userInfo.platform);
        const uInfo = await rService.get(`${platform}/user_info`);
        if (uInfo.error) {
          commit('destroyUserInfo', state.userInfo.platform);
          return alertS.error('Error getting User Info');
        } else {
          commit('updateUserInfo', {
            userInfo: uInfo,
            status: true
          })
          if (!state.userInfo.bot_token) {
            dispatch('regenerateToken')
          }
        }
      } catch (e) {
        console.log(e);
      }
    },
    async authenticate({ commit, state, dispatch }, { access_token, platform }) {
      state.loading = true;
      try {
        rService.setupAccessToken(access_token);
        platform = gPlatform(platform);
        const uInfo = await rService.get(`${platform}/user_info`);
        if (uInfo.error) {
          commit('destroyUserInfo', platform);
          state.loading = false;
          return alertS.error('Error getting User Info');
        } else {
          if (state.help) {
            commit('finishHelp');
          }
          commit('updateUserInfo', {
            userInfo: uInfo,
            status: true
          });
          if (!state.userInfo.bot_token) {
            dispatch('regenerateToken')
          }
        }
      } catch (e) {
        state.unique_id = ''
        console.log(e);
      }
      state.loading = false;
    }
  },
  getters: {
    menuItems: state => {
      return state.menuItems
    },
    languagePopup: state => {
      return state.languagePopup
    },
    copied: state => {
      return state.copied;
    },
    snackBarOpen: state => {
      return state.snackbar.open;
    },
    sheetOpen: state => {
      return state.sheet;
    },
    languagePopupOpen: state => {
      return state.languagePopup;
    },
    safe: state => {
      return state.safe;
    },
    sideBarStatus: state => {
      return state.sideBarStatus;
    },
    loadingBar: state => {
      return state.loadingBar;
    },
    userInfo: state => {
      return state.userInfo;
    },
    loggedIn: state => {
      return (state.userInfo.token != null && state.userInfo.token !== undefined && state.userInfo.token != '') || state.unique_id;
    },
    login_dialog: state => {
      return state.login_dialog;
    },
    platformState: state => {
      return state.platformState;
    },
    scrollDown: state => {
      return state.scrollDown;
    },
    timerInfo: state => {
      return state.timer;
    },
    limitReachedAbs: state => {
      return state.timer && state.timer.max && state.timer.current >= state.timer.max;
    },
    limitReached: state => {
      return !state.timer || state.timer.current === state.timer.max;
    },
    snackbar: state => {
      return state.snackbar;
    },
    navBarStatus: state => {
      return state.navBarStatus;
    },
    loading: state => {
      /* const values = Object.values(state.platformState);
      const isLoading = values.includes(2) && !values.includes(1); */
      return state.loading;
    },
    activeCooldown: state => {
      return state.activeCooldown;
    }
  }
});
