







import Vue from 'vue';
import Alert from '@/services/AlertService/AlertService';
import rService from '@/services/RequestService/RequestServiceSetup';
import alertS from '@/services/AlertService/AlertService';

/* eslint-disable @typescript-eslint/camelcase */
export default {
  name: 'DiscordLogin',
  props: {},
  components: {},
  data() {
    return {
      website: document.location.origin,
      linkLoading: false,
      platform: 'discord',
      disableBtn: true,
      logoutEnabled: false,
      renderParams: {
        width: 250,
        height: 50,
        longtitle: true
      }
    };
  },
  methods: {
    onFailure(err) {},
    async onSuccess(googleUser) {},
    triggerBtn() {},
    async loginDiscord() {
      this.linkLoading = true;
      const website = this.website;
      const discordLink = await rService.post(`${this.platform}/get_link`, { website }, true, false);
      if (discordLink.error) {
        this.linkLoading = false;
        alertS.error('Error with Discord Login');
      } else {
        window.location.href = discordLink.link;
      }
    }
  }
};
