
















































import { mapActions, mapGetters } from 'vuex';
import alertS from '../../services/AlertService/AlertService';
import ProfilePopup from '../Profile/NewProfile.vue';

export default {
  name: 'NavBar',
  components: {
    ProfilePopup,
  },
  props: {
    mobile: {
      Type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      windowPos: 0,
      hideTitle: false,
      showUserInfo: false,
      title: 'CheckLeaked.CC',
    };
  },
  beforeMount() {
    this.$store.subscribe((mutation, state) => {
      switch (mutation.type) {
        case 'logout':
          this.logout();
          break;
        case 'logout_instant':
          this.logout_instant();
          break;
      }
    });
  },
  methods: {
    ...mapActions({
      reset_logout_socket: 'socket/reset_logout_socket',
      setLoginDialog: 'setLoginDialog',
    }),
    logoutFacebook() {
      const w: any = window;
      if (w.FB) w.FB.logout();
    },
    logOutGoogle() {
      // Logout google, remove access_token.
    },
    logout_instant() {
      this.logOutGoogle();
      this.logoutFacebook();
      this.setLoginDialog(false);
      this.$store.commit('destroyUserInfo', 'global');
    },
    logout() {
      this.logOutGoogle();
      this.logoutFacebook();
      const self = this;
      this.$swal
        .fire({
          title: 'Are you sure?',
          text: 'You are about to logout!',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Yes!',
        })
        .then((result) => {
          if (result.value) {
            alertS.autoClose('Closing session...', async (res) => {
              this.$store.commit('destroyUserInfo', 'global');
            });
            this.setLoginDialog(false);
          }
        });
    },
  },
  mounted() {},
  computed: {
    ...mapGetters({
      userInfo: 'userInfo',
      socket_logout: 'socket/socket_logout',
    }),
  },
  watch: {
    socket_logout(newVal) {
      if (newVal == true) {
        alertS.autoClose('Closing session everywhere...', () => {
          this.$store.commit('destroyUserInfo', 'global');
          this.reset_logout_socket();
        });
      }
    },
  },
};
