import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
Vue.use(VueRouter);
function generateMeta(title: string, description: string) {
  return {
    title: 'CheckLeaked.cc - ' + title,
    metaTags: [
      {
        name: 'description',
        content: description
      },
      {
        property: 'og:description',
        content: description
      },
      {
        property: 'twitter:description',
        content: description
      },
      {
        property: 'twitter:title',
        content: title
      }
    ]
  };
}

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'landing',
    component: () => import('../views/Landing/Landing.vue'),
    meta: {
      title: 'CheckLeaked.cc - (Leak Search Engine)',
      metaTags: [
        {
          name: 'description',
          content: 'Protect your account, discover its leaks, avoid using insecure passwords. Secure your company and your person, know the passwords and information that hackers already have about you.'
        },
        {
          property: 'og:description',
          content: 'Powered by Leakcheck.io. Is your account safe?. Have you been pwned? Check it here.'
        }
      ]
    }
  },
  {
    path: '/bot',
    name: 'bot'
  },
  {
    path: '/discord',
    name: 'discord',
    component: () => import('../views/Discord.vue'),
  },
  {
    path: '/check/:id?/:type?/:entry?',
    name: 'home',
    props: {
      typeSelected: {
        type: String,
        required: false,
        default: ''
      },
      toSearch: {
        type: String,
        required: false,
        default: ''
      },
      entrySearch: {
        type: String,
        required: false,
        default: ''
      },
      id: {
        type: Number,
        required: false,
        default: 0
      },
      type: {
        type: String,
        required: false,
        default: ''
      },
      entry: {
        type: String,
        required: false,
        default: ''
      }
    },
    component: () => import('../views/checkAccount.vue'),
    meta: {
      ...generateMeta('Checker', 'Check if your account was leaked')
    }
  },
  {
    path: '/breaches/:breach?',
    name: 'breaches',
    component: () => import('../views/breaches.vue'),
    meta: {
      ...generateMeta('Breaches', 'Check the latest breaches in the net')
    }
  },
  {
    path: '/none',
    component: () => import('../views/textView.vue'),
    children: [
      {
        path: '/bots',
        name: 'bots',
        component: () => import('../views/bots.vue'),
        meta: {
          ...generateMeta('CheckLeaked.cc Bots', 'Bots to access our services')
        }
      },
      {
        path: '/updates',
        name: 'updates',
        component: () => import('../views/updates.vue'),
        meta: {
          ...generateMeta('CheckLeaked.cc Updates', 'Our latest changes')
        }
      },
      {
        path: '/chromeExtension',
        name: 'ChromeExtension',
        component: () => import('../views/chromeExtension.vue'),
        meta: {
          ...generateMeta('Chrome Extension', 'Our chrome extension')
        }
      },
      {
        path: '/blogs/:link?/:post?',
        name: 'Blogs',
        component: () => import('../views/blogs.vue'),
        meta: {
          ...generateMeta('Blogs', 'The blogs we follow')
        }
      },
      {
        path: '/single_breach/:name',
        name: 'SingleBreach',
        component: () => import('../views/single_breach.vue'),
        meta: {
          ...generateMeta('Single Breach', 'All the info about the breach')
        }
      },
      {
        path: '/botLinks',
        name: 'botLinks',
        component: () => import('../views/botLinks.vue'),
        meta: {
          ...generateMeta('BotLinks', 'Use our bots to keep yourself updated'),
          requiresAuth: false
        }
      },
      {
        path: '/redeem',
        name: 'redeem',
        component: () => import('../views/redeem.vue'),
        meta: {
          ...generateMeta('Redeem', 'Redeem your key, get special features'),
          requiresAuth: true
        }
      },
      {
        path: '/apk',
        name: 'apk',
        component: () => import('../views/apk.vue'),
        meta: {
          ...generateMeta('APK', 'Download our android app from the playstore'),
          requiresAuth: true
        }
      },
      {
        path: '/faq',
        name: 'faq',
        component: () => import('../views/faq.vue'),
        meta: {
          requiresAuth: true,
          ...generateMeta('FAQ', 'Most frequent questions about our service')
        }
      },
      {
        path: '/myEntries/:entry?',
        name: 'myEntries',
        component: () => import('../views/myEntries.vue'),
        meta: {
          requiresAuth: true,
          ...generateMeta('My Entries', 'All the data you searches with us')
        }
      },
      {
        path: '/myEntriesDehashed/:entry?',
        name: 'myEntriesDehashed',
        component: () => import('../views/mySearchesDehashed.vue'),
        meta: {
          path: 'ds',
          requiresAuth: true,
          ...generateMeta('My Entries Dehashed', 'All the data you searches with us')
        }
      },
      {
        path: '/myEntriesExp/:entry?',
        name: 'myEntriesExp',
        component: () => import('../views/mySearchesDehashed.vue'),
        meta: {
          path: 'ex',
          requiresAuth: true,
          ...generateMeta('My Entries Experimental', 'All the data you searches with us')
        }
      },
      {
        path: '/privacyPolicy',
        name: 'privacy',
        component: () => import('../views/privacyPolicies.vue'),
        meta: {
          ...generateMeta('Privacy Policies', 'Privacy Policies')
        }
      },
      {
        path: '/contact',
        name: 'contact',
        component: () => import('../views/contact.vue'),
        meta: {
          ...generateMeta('Contact', 'Do not hesitate to contact us if you have any doubt')
        }
      },
      {
        path: '/pricing',
        name: 'pricing',
        component: () => import('../views/pricing.vue'),
        meta: {
          ...generateMeta('Pricing', 'Our special packages, from 3 USD a month')
        }
      },

      {
        path: '/termsAndConditions',
        name: 'terms',
        component: () => import('../views/termsAndConditions.vue'),
        meta: {
          ...generateMeta('Terms and Conditions', 'Our Terms and Conditions')
        }
      },
      {
        path: '/tracking',
        name: 'tracking',
        component: () => import('../views/tracking.vue'),
        meta: {
          ...generateMeta('Tracking System', 'Automatic daily checks (Only Available for paid users)')
        }
      },
      {
        path: '/tracking/history/:type/:entry',
        name: 'trackingHistory',
        component: () => import('../views/trackingHistory.vue'),
        meta: {
          ...generateMeta('Tracking History', 'Check Tracking History of your Entry (Only Available for paid users)')
        }
      },
      {
        path: '/tracking/search/:id',
        name: 'trackingData',
        component: () => import('../views/checkAccount.vue'),
        meta: {
          ...generateMeta('Tracking Data', 'Specific Data from Entry')
        }
      },
      {
        path: '/tools',
        name: 'tools',
        component: () => import('../views/tools.vue'),
        meta: {
          ...generateMeta('Tools', 'CheckLeaked.cc tools')
        }
      },
      {
        path: '/destroy/:id',
        name: 'destroy',
        component: () => import('../views/destroy.vue'),
        meta: {
          ...generateMeta('Destroy', 'CheckLeaked.cc destroy account')
        }
      },
      {
        path: '/whatsappChecker',
        name: 'whatsappChecker',
        component: () => import('../views/whatsappChecker.vue'),
        meta: {
          ...generateMeta('Whatsapp Checker', 'Get Profile Information from Whatsapp Number')
        }
      },
      {
        path: '/googleChecker',
        name: 'googleChecker',
        component: () => import('../views/googleChecker.vue'),
        meta: {
          ...generateMeta('Google Checker', 'Get Profile Information from Google Email')
        }
      },
    ]
  },
  {
    path: '/experimental/check/:type?/:entry?',
    name: 'experimentalCheck',
    component: () => import('../views/snusBase.vue'),
    meta: {
      ...generateMeta('SnusBase Check', 'Search by Name / IP / VIN. Only for Plus / Pro Users')
    }
  },
  {
    path: '/experimental/:entry/:type/:wildcard',
    name: 'experimentalSearchEntry',
    component: () => import('../views/snusBase.vue'),
    meta: {
      ...generateMeta('Experimental Search Entry', 'Search by Name / IP / VIN. Only for Plus / Pro Users')
    }
  },
  {
    path: '/dehashed/check/:type?/:entry?',
    name: 'dehashedCheck',
    component: () => import('../views/dehashedSearch.vue'),
    meta: {
      ...generateMeta('Dehashed Check', 'Search by Name / IP / VIN. Only for Plus / Pro Users')
    }
  },
  {
    path: '/entryDehashed/:query/:pages',
    name: 'dehashedSearchEntry',
    component: () => import('../views/dehashedSearch.vue'),
    meta: {
      ...generateMeta('Dehashed Search Entry', 'Search by Name / IP / VIN. Only for Plus / Pro Users')
    }
  },
  {
    path: '/passwordStrength',
    name: 'passwordStrength',
    component: () => import('../views/passwordStrength.vue'),
    meta: {
      ...generateMeta('Password Strength', 'Check how strong your password is')
    }
  },
  {
    path: '/settings',
    name: 'settings',
    component: () => import('../views/settings.vue'),
    meta: {
      ...generateMeta('Settings', 'Change your preferences')
    }
  },
  { path: '/404', component: () => import('../views/notFound.vue') },
  { path: '*', redirect: '/404' }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior(to, from, savedPosition) {
    console.log("Scroll", to, from, savedPosition);
    const myDiv = document.getElementById('v-main');
    if (myDiv) myDiv.scrollTo(0, 0);
  },
  routes
});

router.beforeEach((to, from, next) => {
  //if (!from) return next();
  const nearestWithTitle = to.matched
    .slice()
    .reverse()
    .find(r => r.meta && r.meta.title);

  const nearestWithMeta = to.matched
    .slice()
    .reverse()
    .find(r => r.meta && r.meta.metaTags);
  const previousNearestWithMeta = from.matched
    .slice()
    .reverse()
    .find(r => r.meta && r.meta.metaTags);

  if (nearestWithTitle) document.title = nearestWithTitle.meta.title;

  Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(el => el.parentNode.removeChild(el));

  if (!nearestWithMeta) return next();

  nearestWithMeta.meta.metaTags
    .map(tagDef => {
      const tag = document.createElement('meta');

      Object.keys(tagDef).forEach(key => {
        tag.setAttribute(key, tagDef[key]);
      })

      tag.setAttribute('data-vue-router-controlled', '');

      return tag;
    })
    .forEach(tag => document.head.appendChild(tag));

  next();
})

export default router;
