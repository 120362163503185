



















import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'LanguagePopUp',
  props: {},
  data() {
    return {
      dialogm1: null,
      dialog: false,
      exist: true,
      timer: null
    };
  },
  beforeDestroy() {
    if(this.timer) clearTimeout(this.timer);
  },
  methods: {
    ...mapActions(['toggleNavBar', 'enableLanguagePopup']),
    trans(text) {
      return this.$vuetify.lang.t(`$vuetify.${text}`);
    },
    save() {
      this.$vuetify.lang.current = this.dialogm1;
      this.$moment.locale(this.dialogm1);
      localStorage.language = this.dialogm1;
      this.enableLanguagePopup(false);
    }
  },
  computed: {
    ...mapGetters(['languagePopup']),
    locales() {
      return Object.keys(this.$vuetify.lang.locales).map(el => {
        return {
          value: el,
          label: this.$vuetify.lang.t(`$vuetify.Locales.${el}`)
        };
      })
    }
  },
  created() {
    this.dialogm1 = this.$vuetify.lang.current;
  },
  mounted() {
    const locales = this.$vuetify.lang.locales;
  },
  watch: {
    languagePopup(newVal) {
      alert(newVal);
      if (newVal == true) this.dialogm1 = this.$vuetify.lang.current;

      if (newVal == true && !this.desktop) {
        this.toggleNavBar();
      }
      this.dialogm1 = this.$vuetify.lang.current;
    },
    $route(to, from) {
      this.exist = false;
      this.timer = setTimeout(() => {
        this.exist = true;
      }, 500);
      this.enableLanguagePopup(false);
    }
  }
};
