// This is your plugin object. It can be exported to be used anywhere.
const MyLoadingPlugin = {
  // The install method is all that needs to exist on the plugin object.
  // It takes the global Vue object as well as user-defined options.
  install(Vue, options) {
    // We call Vue.mixin() here to inject functionality into all components.
    Vue.mixin({
      data() {
        return {
          windowWidth: window.screen.width
        }
      },
      methods: {
        loadBarS(status: boolean) {
          this.$store.dispatch('loadStatus', status);
        },
        $copied(text: string) {
          this.$store.dispatch('copied', text);
        },
        getWindowWidth() {
          this.windowWidth = window.screen.width;
        }
      },
      computed: {
        desktop() {
          return this.windowWidth >= 768;
        }
      },
      beforeMount() {
        window.addEventListener('resize', this.getWindowWidth);
      }
    });
  }
};

export default MyLoadingPlugin;
